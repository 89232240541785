import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { error } from "src/theme/palette";
import { server } from "src/constant/axios_config";
import { toast } from "react-toastify";

//Create roles by createAsyncThunk
export const CreateRoles = createAsyncThunk("create/roles", async (data, { rejectWithValue }) => {
  try {
    const response = await server.post("roles", data);
    return response?.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

//fetch role by id
export const fetchRoleById = createAsyncThunk("fetch/role/id", async (id, { rejectWithValue }) => {
  try {
    const response = await server.get(`roles/${id}`);
    return response?.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

//Read roles by createAsyncThunk
export const fetchRoles = createAsyncThunk("read/roles", async (payload, { rejectWithValue }) => {
  try {
    const { page = 0, pageSize = 5, sortedByKey = 'created_at', ascending = true, searchName = "", searchEmail, status = "", isInternal = "" } = payload;
    let url = `roles?page=${page + 1 || ""}&page_size=${pageSize || ""}&role=${searchName || ""}&sort_by=${sortedByKey}&ascending=${ascending}`
    if (isInternal !== "") url += `&is_internal=${isInternal || false}`
    const response = await server.get(url);
    return response?.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
//Update roles by createAsyncThunk
export const UpdateRoles = createAsyncThunk("updates/roles", async (data, { rejectWithValue }) => {
  try {
    const response = await server.patch(`roles/${data.id}`, data);
    return response?.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

// Update status roleSlice.js
export const updateRoleStatus = createAsyncThunk("update/role/status", async (payload, { rejectWithValue }) => {
  try {
    const response = await server.put(`roles/status/${payload?.id}?status=${payload?.data?.status}`);
    return response?.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

//delete roles with createAsyncThunk
export const DeleteRoles = createAsyncThunk("delete/roles", async (id, { rejectWithValue }) => {
  try {
    const response = await server.delete(`roles/${id}`);
    return response?.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

//slices creation for role Data
export const roleDetail = createSlice({
  name: "role",
  initialState: {
    role: [],
    loading: false,
    error: null,
    searchData: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateRoleStatus.fulfilled, (state, action) => {
        if (action?.payload?.success) {
          toast.success("Role Status Has been updated");
          state.role.list = state.role?.list?.map((el) => {
            return el?.id === action?.payload?.data?.id ? action?.payload?.data : el
          })
        }
      })
      .addCase(updateRoleStatus.rejected, (state, action) => {
        // Handle the error if needed
      })


      .addCase(CreateRoles.pending, (state) => {
        // state.loading = true;
      })
      .addCase(CreateRoles.fulfilled, (state, action) => {
        // state.loading = false;
        if (action?.payload?.success) {
          // state.role.push(action?.payload?.data);
          toast.success('Role Created Successfully');
        }
      })
      .addCase(CreateRoles.rejected, (state, action) => {
        // state.loading = false;
        state.error = action.payload.message;
      })


      .addCase(fetchRoles.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchRoles.fulfilled, (state, action) => {
        state.loading = false;
        state.role = action.payload?.data;
      })
      .addCase(fetchRoles.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // fetch Roles by id
      .addCase(fetchRoleById.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchRoleById.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload?.success) {
        }
      })
      .addCase(fetchRoleById.rejected, (state, action) => {
        state.loading = false;
      })



      .addCase(DeleteRoles.pending, (state) => {
        state.loading = true;
      })
      .addCase(DeleteRoles.fulfilled, (state, action) => {
        state.loading = false;
        const { id } = action.payload;
        if (id) {
          state.role = state.role.filter((ele) => ele.id !== id);
        }
      })
      .addCase(DeleteRoles.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(UpdateRoles.pending, (state) => {
        state.loading = true;
      })
      .addCase(UpdateRoles.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.success) {
          toast.success('Role Updated Successfully');
        }
      })
      .addCase(UpdateRoles.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      });


  },
});

export default roleDetail.reducer;