import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { error } from "src/theme/palette";
import { server } from "src/constant/axios_config";
import { toast } from "react-toastify";
import updateObject from "src/utils/updateObject";

//Create insurances by createAsyncThunk
export const createInsurances = createAsyncThunk("create/insurances", async (data, { rejectWithValue }) => {
  try {
    const response = await server.post("insurances", data);
    return response?.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

//fetch insurances by id
export const fetchInsurancesById = createAsyncThunk("fetch/insurances/id", async (id, { rejectWithValue }) => {
  try {
    const response = await server.get(`insurances/${id}`);
    return response?.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

//Read insurances by createAsyncThunk
export const fetchInsurances = createAsyncThunk("insuraceInsurance/Data", async (payload, { rejectWithValue }) => {
  try {
    const { page = 0, pageSize = 5, sortedByKey = 'created_at', ascending = true, searchName = "", searchEmail, status = false, company_id = "", user_id = "" } = payload;
    const response = await server.get(`insurances?page=${page + 1 || ""}&page_size=${pageSize || ""}&name=${searchName || ""}&status=${status || false}&sort_by=${sortedByKey}&ascending=${ascending}&company_id=${company_id}&user_id=${user_id}`);
    return response?.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});
//Update insurances by createAsyncThunk
export const updateInsurances = createAsyncThunk("updates/insurances", async (data, { rejectWithValue }) => {
  try {
    const response = await server.patch(`insurances/${data.id}`, data);
    return response?.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

// Update status InsurancesSlice.js
export const updateInsurancesStatus = createAsyncThunk("update/insurances/status", async (payload, { rejectWithValue }) => {
  try {
    const response = await server.put(`insurances/status/${payload?.id}?status=${payload?.data?.status}`);
    return response?.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

//delete insurances with createAsyncThunk
export const deleteInsurances = createAsyncThunk("delete/insurances", async (id, { rejectWithValue }) => {
  try {
    const response = await server.delete(`insurances/${id}`);
    return response?.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

//slices creation for insurances Data
export const insuranceDetails = createSlice({
  name: "insurances",
  initialState: {
    insurances: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // update insurance status

      .addCase(updateInsurancesStatus.pending, (state, action) => {
        // Handle the error if needed
        state.loading = true
      })
      .addCase(updateInsurancesStatus.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.success) {
          toast.success("Insurance Status Has been updated");
          const updatedInsurances = state.insurances.list.map((insurances) => {
            if (insurances.id === action.payload.data.id) {
              return updateObject(insurances, { status: action.payload.data.status });
            }
            return insurances;
          });
          state.insurances.list = updatedInsurances;
        }
      })
      .addCase(updateInsurancesStatus.rejected, (state, action) => {
        // Handle the error if needed
        state.loading = false;
      })

      // create insurance
      .addCase(createInsurances.pending, (state) => {
        state.loading = true;
      })
      .addCase(createInsurances.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.success) {
          state.insurances?.list?.push(action.payload?.data);
          toast.success("Insurances Created Succesfully")
        }
      })
      .addCase(createInsurances.rejected, (state, action) => {
        state.loading = false;
      })

      // fetch insurancess
      .addCase(fetchInsurances.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchInsurances.fulfilled, (state, action) => {
        state.loading = false;
        state.insurances = action.payload?.data;
      })
      .addCase(fetchInsurances.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // fetch Insurance by id
      .addCase(fetchInsurancesById.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchInsurancesById.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload?.success) {
        }
      })
      .addCase(fetchInsurancesById.rejected, (state, action) => {
        state.loading = false;
      })


      // delete insurancess
      .addCase(deleteInsurances.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteInsurances.fulfilled, (state, action) => {
        state.loading = false;
        const { id } = action.payload;
        if (id) {
          state.insurances = state.insurances.filter((ele) => ele.id !== id);
        }
      })
      .addCase(deleteInsurances.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // update insurances
      .addCase(updateInsurances.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateInsurances.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.success) {
          toast.success('Insuraces Updated Successfully');
        }
      })
      .addCase(updateInsurances.rejected, (state, action) => {
        state.error = action.payload.message;
        state.loading = false;
      });


  },
});

export default insuranceDetails.reducer;