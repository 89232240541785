import { Box } from "@mui/material";

export default function LoadingScreen({ uploading }) {

  return (
    <Box
      className="loaderClass"
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{
        height: "100vh",
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)", // semi-transparent backdrop
        zIndex: 9999, // ensure it is on top of other content
      }}
    >
      <section style={{ width: 320, textAlign: "center" }}>
        <div class="loaderss loader-1">
          <div class="loader-outter"></div>
          <div class="loader-inner"></div>
        </div>
      </section>
    </Box >
  );
}
