import { ToastContainer } from 'react-toastify';
import 'src/global.css';
import { useScrollToTop } from 'src/hooks/use-scroll-to-top';
import Routes from 'src/routes';
import ThemeProvider from 'src/theme';
import 'react-toastify/dist/ReactToastify.css';
import 'src/loader.css';
// ----------------------------------------------------------------------

export default function App() {
  useScrollToTop();
  return (
    <ThemeProvider>
      <ToastContainer position="top-right" autoClose={5000} />
      <Routes />
    </ThemeProvider>
  );
}
