import { Box, TextField, Typography, Stack, DialogActions, DialogContent, Container, Autocomplete } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Controller, useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { createInsuranceType, updateInsuranceType, fetchInsuranceTypeById } from 'src/redux/insurancetype/InsuranceTypeSlice.js';
import { useNavigate, useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import PageTitle from 'src/common/component/PageTitle';
import LoadingScreen from 'src/components/loader/LoadingScreen';
import { routesPath } from 'src/constant/contant';

const FormContainer = styled("form")(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

export default function AddUpdateInsuranceTypeView() {
  const navigate = useNavigate();
  const isDetailsPage = location.hash.split("/").includes("details");
  const isEditPage = location?.hash?.split("/").includes("update");
  const dispatch = useDispatch();
  const params = useParams();
  const { loading } = useSelector((state) => state.insurancetype)

  const { register, setValue, handleSubmit, reset, formState: { errors }, control } = useForm({ defaultValues: { name: '', description: '' } });
  const [selectedInsuranceType, setSelectedInsuranceType] = useState(null);
  const [fetchedInsuranceType, setFetchedInsuranceType] = useState(null);

  console.log("selectedInsuranceType", selectedInsuranceType);

  useEffect(() => {
    if (fetchedInsuranceType?.description) setValue("description", fetchedInsuranceType?.description);
    if (fetchedInsuranceType?.name) setValue("name", fetchedInsuranceType?.name);
    if (fetchedInsuranceType?.insurance_type_code) setValue("insurance_type_code", fetchedInsuranceType?.insurance_type_code);
    if (fetchedInsuranceType?.ila_type) {
      setValue("ila_type", fetchedInsuranceType?.ila_type);
      setSelectedInsuranceType({ label: fetchedInsuranceType?.ila_type, value: fetchedInsuranceType?.ila_type })
    }
  }, [fetchedInsuranceType]);

  useEffect(() => {
    const fetchData = async () => {
      if (params?.id) {
        try {
          const res = await dispatch(fetchInsuranceTypeById(params?.id));
          if (res?.payload?.success) {
            setFetchedInsuranceType(res.payload.data);
          } else {
            toast.error(res?.payload?.message);
          }
        } catch (error) {
          console.error(error);
        }
      }
    };

    fetchData();
  }, [params?.id, dispatch]);

  const onSubmit = async (data) => {
    data.ila_type = data?.ila_type?.value;
    console.log("data", data);
    try {
      if (fetchedInsuranceType?.id) {
        data.id = fetchedInsuranceType?.id;
        let res = await dispatch(updateInsuranceType(data));
        if (res?.payload?.success) {
          navigate(`${routesPath?.superAdmin?.insuranceType}`);
        } else {
          toast.error(res?.payload?.message);
        }
      } else {
        let res = await dispatch(createInsuranceType(data));
        if (res?.payload?.success) {
          navigate(`${routesPath?.superAdmin?.insuranceType}`);
        } else {
          toast.error(res?.payload?.message);
        }
      }
    } catch (e) {
      console.error(e);
      toast.error(e?.response?.data?.message);
    }
  };

  return (
    <Container maxWidth="xl">
      {loading && <LoadingScreen />}
      <Box mb={5}>
        <PageTitle title={isDetailsPage ? "Insurance Type Details" : fetchedInsuranceType?.id ? "Edit Insurance Type" : "Add New Insurance Type"} />
      </Box>
      <Card>
        <FormContainer noValidate onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Stack spacing={3} >
              <TextField  {...register('name', { required: true, validate: value => value.trim() !== '' })}
                InputLabelProps={{ shrink: fetchedInsuranceType?.name }}
                variant="outlined"
                size='small'
                label="Enter Insurance Type Name"
                placeholder="Insurance Type Name"
                disabled={isDetailsPage}
              />
              {errors.name && <p className="text-danger " style={{ fontSize: 12 }}>Insurance Type Name is required.</p>}

              <TextField multiline
                rows={3}
                maxRows={4} disabled={isDetailsPage} size='small' InputLabelProps={{ shrink: fetchedInsuranceType?.description }} fullWidth name="description" label="Enter description" placeholder='Description'   {...register('description', { required: true, validate: value => value.trim() !== '' })} />
              {errors.description && <p className="text-danger " style={{ fontSize: 12 }}>Description is required.</p>}

              <TextField  {...register('insurance_type_code', { required: true, validate: value => value.trim() !== '' })}
                InputLabelProps={{ shrink: fetchedInsuranceType?.insurance_type_code }}
                variant="outlined"
                size='small'
                label="Enter Insurance Type Code"
                placeholder="Insurance Type Code"
                disabled={isDetailsPage}
              />
              {errors.insurance_type_code && <p className="text-danger " style={{ fontSize: 12 }}>Insurance Type Code is required.</p>}

              <Controller
                name="ila_type"
                control={control}
                rules={{ required: 'ILA Type is required' }}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    options={[{ label: "Motor", value: "Motor" }, { label: "Property", value: "Property" }, { label: "Marine", value: "Marine" }, , { label: "Others", value: "Others" }]}
                    getOptionLabel={(option) => option?.label}
                    renderInput={(params) => (
                      <TextField {...params} label="ILA Type" variant="outlined" disabled={isDetailsPage} />
                    )}
                    value={selectedInsuranceType}
                    onChange={(event, newValue) => {
                      setSelectedInsuranceType(newValue);
                      field.onChange(newValue);
                    }}
                  />
                )}
              />
              {errors.ila_type && <p className="text-danger">{errors.ila_type.message}</p>}
            </Stack>

          </DialogContent>
          {!isDetailsPage && (
            <DialogActions>
              <LoadingButton
                typeof="submit"
                size="medium"
                type="submit"
                variant="contained"
                style={{ backgroundColor: "#000000" }}
                backgroundColor="#000000"
              >
                {fetchedInsuranceType?.id ? "Update" : "Add"}
              </LoadingButton>
            </DialogActions>
          )}
        </FormContainer>
      </Card>
    </Container>
  );
}