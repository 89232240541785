import React from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { resetPasswords } from 'src/redux/auth/forgetPasswordThunk';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useState } from 'react';

import { Box, Card, Stack, TextField, Typography, IconButton } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import Iconify from 'src/components/iconify';
import { login } from '../../redux/auth/authThunk';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import logo from "../../assets/logo/logo.png"
import { LoadingButton } from '@mui/lab';
import { bgGradient } from 'src/theme/css';
import { alpha, useTheme } from '@mui/material/styles';



const ResetPasswordPage = () => {
  const theme = useTheme();
  const [newPassword, setNewPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [showNewPassword, setShowNewPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const dispatch = useDispatch()
  const resetPasswordStatus = useSelector((state) => state.password.resetPasswordStatus);
  const { id, token } = useParams();
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm();

  const handleSubmitFormData = (data) => {
    console.log("data", data)
    if (data?.password !== data?.confirmPassword) {
      toast.error("password not match")
    } else {
      dispatch(resetPasswords({ token, id, newPassword })).then((res) => {
        if (res?.payload?.success) {
          toast?.success("Password change successfully")
          navigate("/")
        }
      });
    }
  };

  const handleClickShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const renderForm = (<>
    <form noValidate onSubmit={handleSubmit(handleSubmitFormData)}>
      <Stack spacing={3} sx={{ my: 3 }}>
        <img
          src={logo}
          alt=""
          width={360}
          height={140}
          style={{ margin: "auto" }}
        />
        <Typography variant="h4" style={{}} >Reset Password  {process.env.REACT_APP_NAME}</Typography>
        <TextField
          {...register('password', { required: true, onChange: (e) => setNewPassword(e?.target?.value) })}
          label="Password"
          name="password"
          type={showNewPassword ? 'text' : 'password'}
          value={newPassword}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowNewPassword(!showNewPassword)} edge="end">
                  <Iconify icon={showNewPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {errors.password && <p className="text-danger " style={{ fontSize: 12 }}>Password is required.</p>}
        <TextField
          {...register('confirmPassword', { required: true, onChange: (e) => setConfirmPassword(e?.target?.value) })}
          name="confirmPassword"
          label="Confirm Password"
          type={showConfirmPassword ? 'text' : 'password'}
          value={confirmPassword}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowConfirmPassword(!showConfirmPassword)} edge="end">
                  <Iconify icon={showConfirmPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {errors.confirmPassword && <p className="text-danger " style={{ fontSize: 12 }}>Password is required.</p>}
      </Stack>

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        color="inherit"
        style={{
          backgroundColor: "#000000",
          "&:hover": {
            backgroundColor: "#FF7725",
          },
        }}
        disabled={resetPasswordStatus === 'loading'}
      >
        {resetPasswordStatus === 'loading' ? 'Resetting...' : 'Update Password'}
      </LoadingButton>

    </form>
  </>);

  return (
    <Box
      sx={{
        ...bgGradient({
          color: alpha(theme.palette.background.default, 0.9),
          imgUrl: '/assets/background/overlay_4.jpg',
        }),
        height: 1,
      }}
    >
      <Stack alignItems="center" justifyContent="center" sx={{ height: 1 }}>
        {/* <Logo /> */}
        <Card
          sx={{
            p: 4,
            width: 1,
            maxWidth: 500,
          }}
        >
          {renderForm}
        </Card>
      </Stack>
    </Box >
  );
}

export default ResetPasswordPage;