// Import the functions you need from the SDKs you need
import { getApps, initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getMessaging } from 'firebase/messaging';
// TODO: Add SDKs for Firebase products that you want to use

const firebaseConfig = {
    apiKey: "AIzaSyAwcp_FXuXVv3-W_I-W0d7y0MtV1l1X-HQ",
    authDomain: "afri-tech.firebaseapp.com",
    projectId: "afri-tech",
    storageBucket: "afri-tech.appspot.com",
    messagingSenderId: "675111561358",
    appId: "1:675111561358:web:ab456aa008c9a34e1081df",
    measurementId: "G-7ZSJNVCTG5"
};

// Initialize Firebase
const app = getApps()?.length === 0 ? initializeApp(firebaseConfig) : getApps()[0];

export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();
const messaging = getMessaging(app);
export { messaging };