import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
// components
import { Login } from 'src/pages/unauthenticated-routes';
import LoadingScreen from 'src/components/loader/LoadingScreen';
import { useDispatch, useSelector } from 'react-redux';
import { getMe } from 'src/redux/auth/authThunk';

// ----------------------------------------------------------------------

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default function AuthGuard({ children }) {

  const { isLoggedIn, user } = useSelector((state) => state.auth);
  const { pathname } = useLocation();
  const users = JSON.parse(localStorage.getItem("user"));
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();

  useEffect(() => {
    if (isLoggedIn && users && token) dispatch(getMe(users?.email))
  }, [isLoggedIn, token])

  const [requestedLocation, setRequestedLocation] = useState(null);

  if (!isLoggedIn) {

    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Login />;
  }

  if (requestedLocation && pathname !== requestedLocation) {

    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <> {children} </>;
}

export function LoggedInUser({ children, userType }) {

  // const { isLoggedIn, user } = useSelector((state) => state.auth);
  // const { pathname } = useLocation();
  // const users = JSON.parse(localStorage.getItem("user"));
  // const dispatch = useDispatch();

  // useEffect(() => {
  //   if (users) dispatch(getMe(users?.email))
  // }, [isLoggedIn])

  // const [requestedLocation, setRequestedLocation] = useState(null);

  // if (!isLoggedIn) {

  //   if (pathname !== requestedLocation) {
  //     setRequestedLocation(pathname);
  //   }
  //   return <Login />;
  // }

  // if (requestedLocation && pathname !== requestedLocation) {

  //   setRequestedLocation(null);
  //   return <Navigate to={requestedLocation} />;
  // }

  return <> {children} </>;
}
