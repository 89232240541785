import { Autocomplete, Box, Divider, Grid, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { UpdateCompany, createCompany, fetchCompanyById, companyLogoUpload } from 'src/redux/company/companySlice';
import { useForm, Controller } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import { LoadingButton } from "@mui/lab";
import PageTitle from "src/common/component/PageTitle";
import Iconify from "src/components/iconify/iconify";
import LoadingScreen from "src/components/loader/LoadingScreen";
import { countries, imageUrl } from "src/constant/contant";

const FormContainer = styled("form")(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
}));

const defaultFormData = {
    name: '',
    email: '',
    mobile: '',
    country: '',
    state: '',
    countrycode: 0,
    address: '',
    city: '',
}

export default function AddUpdateCompanyView() {
    const { register, handleSubmit, formState: { errors }, setValue, control } = useForm({ defaultValues: defaultFormData });
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation()
    const params = useParams()
    const { loading } = useSelector((state) => state?.company)
    const isDetailsPage = location?.pathname?.split("/").includes("details")
    const isEditPage = location?.pathname?.split("/").includes("update")
    const [selectedCompany, setSelectedCompany] = useState(null)
    const [formData, setFormData] = useState(defaultFormData);
    const [previewUrl, setPreviewUrl] = useState('');
    const [logoString, setLogoString] = useState('');


    useEffect(() => {
        if (params?.id) {
            dispatch(fetchCompanyById(params?.id)).then((res) => {
                setSelectedCompany(res?.payload?.data)
            })
        }
    }, [params?.id])

    useEffect(() => {
        if (selectedCompany) {
            setFormData({
                name: selectedCompany?.name ? selectedCompany?.name : '',
                insurer_code: selectedCompany?.insurer_code ? selectedCompany?.insurer_code : '',
                email: selectedCompany?.email ? selectedCompany?.email : '',
                mobile: selectedCompany?.mobile ? selectedCompany?.mobile : '',
                country: selectedCompany?.country ? selectedCompany?.country : '',
                state: selectedCompany?.state ? selectedCompany?.state : '',
                countrycode: selectedCompany?.countrycode ? parseInt(selectedCompany?.countrycode) : '',
                address: selectedCompany?.address ? selectedCompany?.address : '',
                city: selectedCompany?.city ? selectedCompany?.city : '',
            })
            setValue("name", selectedCompany?.name)
            setValue("insurer_code", selectedCompany?.insurer_code)
            setValue("email", selectedCompany?.email)
            setValue("mobile", selectedCompany?.mobile)
            setValue("country", selectedCompany?.country)
            setValue("state", selectedCompany?.state)
            setValue("countrycode", parseInt(selectedCompany?.countrycode))
            setValue("address", selectedCompany?.address)
            setValue("city", selectedCompany?.city)
            if (selectedCompany?.logo) {
                setPreviewUrl(`${imageUrl}${selectedCompany?.logo}`);
                setLogoString(selectedCompany?.logo);
            }
        }
    }, [selectedCompany])

    const handleChangeFormData = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: event?.target?.name === "countrycode" ? parseInt(value) : value });
    };

    const onSubmit = (data) => {
        data.logo = logoString;
        if (!params?.id) {
            dispatch(createCompany(data))?.unwrap()?.then((res) => {
                if (res?.success) {
                    navigate(-1)
                }
            });
        } else {
            dispatch(UpdateCompany({ ...data, id: selectedCompany?.id }))?.unwrap().then((res) => {
                if (res?.success) {
                    navigate(-1)
                }
            });
        }
    }

    const handleFileChange = async (e) => {
        try {
            const file = e.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                let data = new FormData();
                data.append('image', file);
                let res = await dispatch(companyLogoUpload(data));
                setLogoString(res?.payload?.data?.url);
                setPreviewUrl(`${imageUrl}${res?.payload?.data?.url}`);
            }
        } catch (error) {
            console.error(error)
        }

    };

    console.log("formData", formData);
    return (<Container maxWidth="xl">
        {loading && <LoadingScreen />}
        <Box mb={5}>
            <PageTitle title={isEditPage ? "Edit Company" : isDetailsPage ? "Company Details" : "Create New Company"} />
        </Box>
        <Card>
            <FormContainer
                noValidate
                onSubmit={handleSubmit(onSubmit)}
                sx={{ padding: "35px", borderRadius: "10px" }}>


                <TextField
                    {...register("name", { required: true, onChange: handleChangeFormData, validate: value => value.trim() !== '' })}
                    value={formData?.name}
                    name="name"
                    label="Company Name"
                    fullWidth
                    disabled={isDetailsPage}
                />
                {errors.name ? (<p className="text-danger">Name is required. </p>) : null}

                <TextField
                    {...register("insurer_code", { required: true, onChange: handleChangeFormData, validate: value => value.trim() !== '' })}
                    value={formData?.insurer_code}
                    name="insurer_code"
                    type="number"
                    label="Insurer Code"
                    fullWidth
                    disabled={isDetailsPage}
                    InputLabelProps={{ shrink: !!formData?.insurer_code }}
                    onWheel={(e) => e.target.blur()}
                />
                {errors.insurer_code ? (<p className="text-danger">Insurer Code is required. </p>) : null}

                <TextField
                    {...register("email", {
                        required: { value: true, message: "Email is required" },
                        pattern: {
                            value: /^[^\s@]+(\+[^\s@]+)?@[^\s@]+\.[^\s@]+$/,
                            message: "Invalid email address.",
                        },
                        onChange: handleChangeFormData
                    })}
                    value={formData?.email}
                    name="email"
                    label="Company Email"
                    type="email"
                    fullWidth
                    disabled={isDetailsPage}
                />
                {errors.email ? (<p className="text-danger"> {errors.email.message} </p>) : null}

                {/* <TextField
                    {...register("mobile", {
                        required: {
                            value: true,
                            message: "Please Enter Mobile Number"
                        },
                        pattern: {
                            value: /^(?!(\d)\1{8})[6-9]\d{9}$/,
                            message: "Please Enter Valid Mobile Number",
                        },
                        onChange: handleChangeFormData
                    })}
                    value={formData?.mobile}
                    type="number"
                    name="mobile"
                    label=" Company Mobile"
                    fullWidth
                    disabled={isDetailsPage}
                />
                {errors.mobile ? (<p className="text-danger "> {errors.mobile.message}  </p>) : null} */}

                <TextField
                    {...register("mobile", {
                        required: {
                            value: true,
                            message: "Please Enter Mobile Number"
                        },
                        pattern: {
                            value: /^[\+0-9\s]{9,}$/, // Adjust the regex according to the required format
                            message: "Please enter a valid mobile number"
                        },
                        onChange: handleChangeFormData
                    })}
                    value={formData?.mobile}
                    type="text"
                    name="mobile"
                    label="Company Mobile"
                    fullWidth
                    disabled={isDetailsPage}
                />
                {errors.mobile ? (<p className="text-danger"> {errors.mobile.message} </p>) : null}

                <TextField
                    {...register("address", { required: true, onChange: handleChangeFormData, validate: value => value.trim() !== '' })}
                    value={formData?.address}
                    name="address"
                    label=" Company Address"
                    fullWidth
                    disabled={isDetailsPage}
                />
                {errors.address ? (<p className="text-danger "> Address is required. </p>) : null}


                <Controller
                    control={control}
                    disabled={isDetailsPage}
                    name="country"
                    rules={{ required: true }}
                    render={({ field }) => (
                        <Autocomplete
                            {...field}
                            value={field.value || null}
                            onChange={(event, newValue) => {
                                field.onChange(newValue);
                            }}
                            options={countries}
                            getOptionLabel={(option) => option}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Country"
                                    variant="outlined"
                                    fullWidth
                                    error={!!errors.country}
                                    helperText={errors.country?.message}
                                />
                            )}
                        />
                    )}
                />
                {errors.country ? (<p className="text-danger "> Country is required. </p>) : null}

                <TextField
                    {...register("state", { required: true, onChange: handleChangeFormData, validate: value => value.trim() !== '' })}
                    value={formData?.state}
                    name="state"
                    label="State"
                    fullWidth
                    disabled={isDetailsPage}
                />
                {errors.state ? (<p className="text-danger "> State is required. </p>) : null}

                <TextField
                    {...register("city", { required: true, onChange: handleChangeFormData, validate: value => value.trim() !== '' })}
                    value={formData?.city}
                    name="city"
                    label="City"
                    fullWidth
                    disabled={isDetailsPage}
                />
                {errors.city ? (<p className="text-danger "> City is required. </p>) : null}


                <div>
                    <Grid container spacing={2}>
                        {!isDetailsPage && <Grid item xs={previewUrl?.includes("logo") ? 8 : 12}>
                            <Typography variant='body2' my={2}>Select Logo</Typography>
                            <span className="dragBox" style={{ height: 200 }} >
                                <Typography className='body2' fontSize={14} color={"primary"}> Drag and Drop image here</Typography>
                                <Divider sx={{ my: 2, width: "50%" }}>
                                    <Typography variant="body2" fontSize={12} sx={{ color: 'text.secondary' }}>
                                        OR
                                    </Typography>
                                </Divider>
                                <LoadingButton
                                    type="submit"
                                    variant="contained"
                                    color="primary">
                                    Choose image
                                </LoadingButton>
                                <input accept="image/*" type="file" className="drop-zone__input" onChange={handleFileChange} />
                            </span>
                        </Grid>}
                        {previewUrl?.includes("logo") && <Grid item xs={4}>
                            <Typography variant='body2' my={2}>Logo</Typography>
                            <div style={{ position: "relative", width: 200, height: 200, boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px" }}>
                                <img src={previewUrl} alt="Preview" style={{ borderRadius: 10, height: "100%", objectFit: "contain" }} />
                                {!isDetailsPage && <Iconify onClick={() => { setPreviewUrl(""); setLogoString("none") }} icon="eva:close-circle-fill" width={25} color={"#f66004"} style={{ verticalAlign: "middle", position: "absolute", top: -8, right: -8, cursor: "pointer" }} />}
                            </div>
                        </Grid>}
                    </Grid>
                </div>


                <Box mt={2} display="flex" justifyContent="end">
                    <LoadingButton
                        loading={loading}
                        typeof='submit'
                        size="medium"
                        type="submit"
                        variant="contained"
                        style={{ backgroundColor: "#000000" }}
                        backgroundColor="#000000"
                    >
                        {isEditPage ? "Update" : "Add"}
                    </LoadingButton>
                </Box>

            </FormContainer>
        </Card>
    </Container>)
} 