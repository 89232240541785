import { useState } from 'react';
import React from "react"
import { Box, Card, Stack, TextField, Typography, IconButton } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import Iconify from 'src/components/iconify';
import { useDispatch } from 'react-redux';
import { login } from '../../redux/auth/authThunk';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import logo from "../../assets/logo/logo.png"
import { useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { bgGradient } from 'src/theme/css';
import { alpha, useTheme } from '@mui/material/styles';
import { useEffect } from 'react';

import { getToken } from 'firebase/messaging';
import { messaging } from 'src/config/firebase';

export default function Login() {
  const theme = useTheme();
  const dispatch = useDispatch()
  const [showPassword, setShowPassword] = useState(false);
  const [fcmToken, setFcmToken] = useState("");
  const { register, handleSubmit, formState: { errors }, } = useForm();

  useEffect(() => {
    const requestPermission = async () => {
      try {
        await Notification.requestPermission();
        const token = await getToken(messaging, { vapidKey: "BKmo4y4a50VSUk5vH1hcKV4XbGueSt8yY7FYHfll0xYidKbzzsKPkTcsb9vSnzSBENlxJIsH6Ko8SYC91IKkz-s" });
        setFcmToken(token);
      } catch (error) {
        console.error('Permission denied or error occurred', error);
      }
    };
    requestPermission();

    // onMessage(messaging, (payload) => {
    //   console.log('Message received. ', payload?.notification);
    // });
  }, []);

  console.log("fcmToken", fcmToken);

  const handleLogin = (data) => {
    data.token = fcmToken
    data.device_type = "Website"
    dispatch(login(data))
  }

  const renderForm = (
    <form noValidate onSubmit={handleSubmit(handleLogin)} >
      <Stack spacing={3} >
        <img
          src={logo}
          alt=""
          width={360}
          height={140}
          style={{ margin: "auto" }}
        />
        <Typography variant="h4" style={{}} >Sign In  {process.env.REACT_APP_NAME}</Typography>
        <TextField name="email" label="Email"  {...register('email', {
          required: true, pattern: {
            value: /^[^\s@]+(\+[^\s@]+)?@[^\s@]+\.[^\s@]+$/,
            message: 'Invalid email address.',
          },
        })} />
        {errors.email && errors.email.type === 'required' && <p className="text-danger " style={{ fontSize: 12 }}>Email is required.</p>}
        {errors.email && errors.email.type === 'pattern' && <p className="text-danger " style={{ fontSize: 12 }}>Invalid email</p>}


        <TextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          {...register('password', { required: true })}
        />
        {errors.password && <p className="text-danger " style={{ fontSize: 12 }}>Password is required.</p>}

      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="flex-end" sx={{ my: 3 }}>
        <Link to="/forgot-password" variant="subtitle2" underline="hover" style={{ color: "#FF6000" }}>
          Forgot password?
        </Link>
      </Stack>

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        color="inherit"
        style={{
          backgroundColor: "#000000",
          "&:hover": {
            backgroundColor: "#FF7725",
          },
        }}
      >
        Login
      </LoadingButton>
    </form>
  );

  return (
    <Box
      sx={{
        ...bgGradient({
          color: alpha(theme.palette.background.default, 0.9),
          imgUrl: '/assets/background/overlay_4.jpg',
        }),
        height: 1,
      }}
    >
      <Stack alignItems="center" justifyContent="center" sx={{ height: 1 }}>
        {/* <Logo /> */}
        <Card
          sx={{
            p: 4,
            width: 1,
            maxWidth: 500,
          }}
        >
          {renderForm}
        </Card>
      </Stack>
    </Box >
  );
}