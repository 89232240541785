import { lazy, Suspense, useEffect, useMemo } from 'react';
import { Navigate, Outlet, useNavigate, useRoutes } from 'react-router-dom';
import DashboardLayout from 'src/layouts/dashboard';
import { useDispatch, useSelector } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import AuthGuard from './AuthGuard';
import GuestGuard from './GuestGuard';
import { authActions } from 'src/redux/auth/authSlice';
import AddUpdateRoleView from 'src/pages/authenticated-routes/role/add-update-role';
import AddUpdateCompanyView from 'src/pages/authenticated-routes/company/add-update-company';
import AddUpdateInsuranceTypeView from 'src/pages/authenticated-routes/insurancetype/add-update-insurancetype'
import LoadingScreen from 'src/components/loader/LoadingScreen';
import { commonVarialbleValue, routesPath } from 'src/constant/contant';
import { element } from 'prop-types';
import { toast } from 'react-toastify';
import { setTokenPermissionsData } from 'src/redux/roles&permission/permissionSlice';

export const AuthenticatedIndexPage = lazy(() => import('src/pages/authenticated-routes'));
export const UnauthenticatedIndexPage = lazy(() => import('src/pages/unauthenticated-routes'));
export const Role = lazy(() => import('src/pages/authenticated-routes/role/index'));
export const Page404 = lazy(() => import('src/pages/page-not-found'));
export const ForgotPassword = lazy(() => import("src/pages/unauthenticated-routes/forgot-password"))
export const ResetPassword = lazy(() => import("src/pages/unauthenticated-routes/reset-password"))
export const ProfilePage = lazy(() => import('src/pages/authenticated-routes/profile/profile'));
export const Company = lazy(() => import('src/pages/authenticated-routes/company'));
export const User = lazy(() => import('src/pages/authenticated-routes/user'));
export const AddUpdateUser = lazy(() => import('src/pages/authenticated-routes/user/add-update-user'));
export const InternalUser = lazy(() => import('src/pages/authenticated-routes/user/internal-user'));
export const ExternalUser = lazy(() => import('src/pages/authenticated-routes/user/external-user'));
export const BrokerUser = lazy(() => import('src/pages/authenticated-routes/user/broker'));

export const Survey = lazy(() => import('src/pages/authenticated-routes/loss-assessor-admin/surveys'));
export const AddUpdateSurvey = lazy(() => import('src/pages/authenticated-routes/loss-assessor-admin/surveys/add-update-survey'));
export const SurveyILA = lazy(() => import('src/pages/authenticated-routes/loss-assessor-admin/surveys/survey-ila'));

export const LossAssessorUser = lazy(() => import("src/pages/authenticated-routes/user/loss-assessor"));
export const PolicyOwnerUser = lazy(() => import("src/pages/authenticated-routes/user/policy-owner"))
export const UpdateProfileView = lazy(() => import('src/pages/authenticated-routes/profile/update-profile'));
export const CompanyAdminDashboard = lazy(() => import('src/pages/authenticated-routes/company-admin/dashboard'));
export const CompnayBroker = lazy(() => import('src/pages/authenticated-routes/company-admin/broker'));
export const CompnayUser = lazy(() => import('src/pages/authenticated-routes/company-admin/user'));
export const CompnayLossAssesor = lazy(() => import('src/pages/authenticated-routes/company-admin/loss-assessor'));
export const CompnayPolicyOwner = lazy(() => import('src/pages/authenticated-routes/company-admin/policy-owner'));
export const CompnayInsurance = lazy(() => import('src/pages/authenticated-routes/company-admin/insurance'));
export const BrokerAdminDashboard = lazy(() => import('src/pages/authenticated-routes/broker-admin/dashboard'));
export const LossAssesorAdminDashboard = lazy(() => import('src/pages/authenticated-routes/loss-assessor-admin/dashboard'));
export const Claims = lazy(() => import('src/pages/authenticated-routes/loss-assessor-admin/claim'));
export const AddUpdateClaims = lazy(() => import('src/pages/authenticated-routes/loss-assessor-admin/claim/add-update-claim'));
export const PolicyOwnerAdminDashboard = lazy(() => import('src/pages/authenticated-routes/policy-owner-admin/dashboard'));
export const InsuranceType = lazy(() => import("src/pages/authenticated-routes/insurancetype"))
export const Insurances = lazy(() => import("src/pages/authenticated-routes/insurance"))
export const Notifications = lazy(() => import("src/pages/authenticated-routes/notification"))
export const AddUpdateInsurance = lazy(() => import("src/pages/authenticated-routes/insurance/add-update-insurance"))
export const Document = lazy(() => import("src/pages/authenticated-routes/document"))
export const AddUpdateDocument = lazy(() => import('src/pages/authenticated-routes/document/add-update-document'));


const parseToken = (token) => {
  if (!token) return { role: null, permissions: [] };
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(atob(base64).split('').map(c =>
    '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)).join(''));
  const tokendata = JSON.parse(jsonPayload);
  console.log("tokendata", tokendata)
  const grouped = tokendata?.permissions?.reduce((acc, permission) => {
    const category = permission?.replace(/_(read|create|update|delete|details)$/, '');
    const action = permission?.split("_")[permission?.split("_")?.length - 1]
    acc[category] = { ...acc[category], [action]: true };
    return acc;
  }, {});
  return {
    role: tokendata?.role,
    permissions: grouped && Object?.keys(grouped)?.map(category => ({ title: category, ...grouped[category] })),
  };
};

const compnayPermissionRouterConfig = {
  dashboard_company_admin: {
    base: { path: '', element: <Outlet /> },
    children: [
      { path: '', element: <CompanyAdminDashboard /> },
    ]
  },
  user: {
    base: { path: commonVarialbleValue?.user, element: <Outlet /> },
    children: [
      { path: '', element: <CompnayUser /> },
      { path: commonVarialbleValue?.add, element: <AddUpdateUser /> },
      { path: `${commonVarialbleValue?.update}/:email`, element: <AddUpdateUser /> },
      { path: `${commonVarialbleValue?.details}/:email`, element: <AddUpdateUser /> },
    ]
  },
  broker: {
    base: { path: commonVarialbleValue?.broker, element: <Outlet /> },
    children: [
      { path: '', element: <CompnayBroker /> },
      { path: commonVarialbleValue?.add, element: <AddUpdateUser /> },
      { path: `${commonVarialbleValue?.update}/:email`, element: <AddUpdateUser /> },
      { path: `${commonVarialbleValue?.details}/:email`, element: <AddUpdateUser /> },
    ]
  },
  loss_assesor: {
    base: { path: commonVarialbleValue?.lossAssessor, element: <Outlet /> },
    children: [
      { path: '', element: <CompnayLossAssesor /> },
      { path: commonVarialbleValue?.add, element: <AddUpdateUser /> },
      { path: `${commonVarialbleValue?.update}/:email`, element: <AddUpdateUser /> },
      { path: `${commonVarialbleValue?.details}/:email`, element: <AddUpdateUser /> },
    ]
  },
  policy_owner: {
    base: { path: commonVarialbleValue?.policyOwner, element: <Outlet /> },
    children: [
      { path: '', element: <CompnayPolicyOwner /> },
      { path: commonVarialbleValue?.add, element: <AddUpdateUser /> },
      { path: `${commonVarialbleValue?.update}/:email`, element: <AddUpdateUser /> },
      { path: `${commonVarialbleValue?.details}/:email`, element: <AddUpdateUser /> },
    ]
  },
  insurance: {
    base: { path: commonVarialbleValue?.insurances, element: <Outlet /> },
    children: [
      { path: '', element: <CompnayInsurance /> },
      { path: commonVarialbleValue?.add, element: <AddUpdateInsurance /> },
      { path: `${commonVarialbleValue?.update}/:id`, element: <AddUpdateInsurance /> },
      { path: `${commonVarialbleValue?.details}/:id`, element: <AddUpdateInsurance /> },
    ]
  },
  notification: {
    base: { path: commonVarialbleValue?.notifications, element: <Outlet /> },
    children: [
      { path: '', element: <Notifications /> },
    ]
  },
}

const routeConfigurations = {
  internal: {
    base: { path: `${commonVarialbleValue?.user}/${commonVarialbleValue?.internal}`, element: <Outlet /> },
    children: [
      { path: '', element: <InternalUser /> },
      { path: commonVarialbleValue?.add, element: <AddUpdateUser /> },
      { path: `${commonVarialbleValue?.update}/:email`, element: <AddUpdateUser /> },
      { path: `${commonVarialbleValue?.details}/:email`, element: <AddUpdateUser /> },
      // { path: "sub-internal", element: <Outlet />, children: [{ path: "", element: <h1>comming soon sub internal</h1> }] }
    ],
  },
  external: {
    base: { path: `${commonVarialbleValue?.user}/${commonVarialbleValue?.external}`, element: <Outlet /> },
    children: [
      { path: '', element: <ExternalUser /> },
      { path: commonVarialbleValue?.add, element: <AddUpdateUser /> },
      { path: `${commonVarialbleValue?.update}/:email`, element: <AddUpdateUser /> },
      { path: `${commonVarialbleValue?.details}/:email`, element: <AddUpdateUser /> },
    ],
  },
  broker: {
    base: { path: `${commonVarialbleValue?.user}/${commonVarialbleValue?.broker}`, element: <Outlet /> },
    children: [
      { path: '', element: <BrokerUser /> },
      { path: commonVarialbleValue?.add, element: <AddUpdateUser /> },
      { path: `${commonVarialbleValue?.update}/:email`, element: <AddUpdateUser /> },
      { path: `${commonVarialbleValue?.details}/:email`, element: <AddUpdateUser /> },
    ],
  },
  loss_assesor: {
    base: { path: `${commonVarialbleValue?.user}/${commonVarialbleValue?.lossAssessor}`, element: <Outlet /> },
    children: [
      { path: '', element: <LossAssessorUser /> },
      { path: commonVarialbleValue?.add, element: <AddUpdateUser /> },
      { path: `${commonVarialbleValue?.update}/:email`, element: <AddUpdateUser /> },
      { path: `${commonVarialbleValue?.details}/:email`, element: <AddUpdateUser /> },
    ],
  },
  policy_owner: {
    base: { path: `${commonVarialbleValue?.user}/${commonVarialbleValue?.policyOwner}`, element: <Outlet /> },
    children: [
      { path: '', element: <PolicyOwnerUser /> },
      { path: commonVarialbleValue?.add, element: <AddUpdateUser /> },
      { path: `${commonVarialbleValue?.update}/:email`, element: <AddUpdateUser /> },
      { path: `${commonVarialbleValue?.details}/:email`, element: <AddUpdateUser /> },
    ],
  },
  user: {
    base: { path: commonVarialbleValue?.user, element: <Outlet /> },
    children: [
      { path: '', element: <CompnayUser /> },
      { path: commonVarialbleValue?.add, element: <AddUpdateUser /> },
      { path: `${commonVarialbleValue?.update}/:email`, element: <AddUpdateUser /> },
      { path: `${commonVarialbleValue?.details}/:email`, element: <AddUpdateUser /> },
    ]
  },
  // user: {
  //   base: { path: commonVarialbleValue?.user, element: <Outlet /> },
  //   children: [
  //     { path: '', element: <User /> },
  //     {
  //       path: commonVarialbleValue?.internal,
  //       element: <Outlet />,
  //       children: [
  //         { path: '', element: <InternalUser /> },
  //         { path: commonVarialbleValue?.add, element: <AddUpdateUser /> },
  //         { path: `${commonVarialbleValue?.update}/:email`, element: <AddUpdateUser /> },
  //         { path: `${commonVarialbleValue?.details}/:email`, element: <AddUpdateUser /> },
  //         { path: "sub-internal", element: <Outlet />, children: [{ path: "", element: <h1>comming soon sub internal</h1> }] }
  //       ],
  //     },
  //     {
  //       path: commonVarialbleValue?.external,
  //       element: <Outlet />,
  //       children: [
  //         { path: '', element: <ExternalUser /> },
  //         { path: commonVarialbleValue?.add, element: <AddUpdateUser /> },
  //         { path: `${commonVarialbleValue?.update}/:email`, element: <AddUpdateUser /> },
  //         { path: `${commonVarialbleValue?.details}/:email`, element: <AddUpdateUser /> },
  //       ],
  //     },
  //     {
  //       path: commonVarialbleValue?.broker,
  //       element: <Outlet />,
  //       children: [
  //         { path: '', element: <BrokerUser /> },
  //         { path: commonVarialbleValue?.add, element: <AddUpdateUser /> },
  //         { path: `${commonVarialbleValue?.update}/:email`, element: <AddUpdateUser /> },
  //         { path: `${commonVarialbleValue?.details}/:email`, element: <AddUpdateUser /> },
  //       ],
  //     },
  //     {
  //       path: commonVarialbleValue?.lossAssessor,
  //       element: <Outlet />,
  //       children: [
  //         { path: '', element: <LossAssessorUser /> },
  //         { path: commonVarialbleValue?.add, element: <AddUpdateUser /> },
  //         { path: `${commonVarialbleValue?.update}/:email`, element: <AddUpdateUser /> },
  //         { path: `${commonVarialbleValue?.details}/:email`, element: <AddUpdateUser /> },
  //       ],
  //     },
  //     {
  //       path: commonVarialbleValue?.policyOwner,
  //       element: <Outlet />,
  //       children: [
  //         { path: '', element: <PolicyOwnerUser /> },
  //         { path: commonVarialbleValue?.add, element: <AddUpdateUser /> },
  //         { path: `${commonVarialbleValue?.update}/:email`, element: <AddUpdateUser /> },
  //         { path: `${commonVarialbleValue?.details}/:email`, element: <AddUpdateUser /> },
  //       ],
  //     },
  //     { path: `${commonVarialbleValue?.details}/:email`, element: <AddUpdateUser /> },
  //   ],
  // },
  rbac: {
    base: { path: commonVarialbleValue?.role, element: <Outlet /> },
    children: [
      { path: '', element: <Role /> },
      { path: commonVarialbleValue?.add, element: <AddUpdateRoleView /> },
      { path: `${commonVarialbleValue?.update}/:id`, element: <AddUpdateRoleView /> },
      { path: `${commonVarialbleValue?.details}/:id`, element: <AddUpdateRoleView /> },
    ],
  },
  insurance_type: {
    base: { path: commonVarialbleValue?.insurancetype, element: <Outlet /> },
    children: [
      { path: '', element: <InsuranceType /> },
      { path: commonVarialbleValue?.add, element: <AddUpdateInsuranceTypeView /> },
      { path: `${commonVarialbleValue?.update}/:id`, element: <AddUpdateInsuranceTypeView /> },
      { path: `${commonVarialbleValue?.details}/:id`, element: <AddUpdateInsuranceTypeView /> },
    ]
  },
  document: {
    base: { path: commonVarialbleValue?.document, element: <Outlet /> },
    children: [
      { path: '', element: <Document /> },
      { path: commonVarialbleValue?.add, element: <AddUpdateDocument /> },
      { path: `${commonVarialbleValue?.update}/:id`, element: <AddUpdateDocument /> },
      { path: `${commonVarialbleValue?.details}/:id`, element: <AddUpdateDocument /> },
    ]
  },
  company: {
    base: { path: commonVarialbleValue?.company, element: <Outlet /> },
    children: [
      { path: '', element: <Company /> },
      { path: commonVarialbleValue?.add, element: <AddUpdateCompanyView /> },
      { path: `${commonVarialbleValue?.update}/:id`, element: <AddUpdateCompanyView /> },
      { path: `${commonVarialbleValue?.details}/:id`, element: <AddUpdateCompanyView /> },
    ],
  },
  insurance: {
    base: { path: commonVarialbleValue?.insurances, element: <Outlet /> },
    children: [
      { path: '', element: <Insurances /> },
      { path: commonVarialbleValue?.add, element: <AddUpdateInsurance /> },
      { path: `${commonVarialbleValue?.update}/:id`, element: <AddUpdateInsurance /> },
      { path: `${commonVarialbleValue?.details}/:id`, element: <AddUpdateInsurance /> },
    ]
  },
  notification: {
    base: { path: commonVarialbleValue?.notifications, element: <Outlet /> },
    children: [
      { path: '', element: <Notifications /> },
    ]
  },
  survey: {
    base: { path: commonVarialbleValue?.survey, element: <Outlet /> },
    children: [
      { path: '', element: <Survey /> },
      { path: commonVarialbleValue?.add, element: <AddUpdateSurvey /> },
      { path: `${commonVarialbleValue?.update}/:id`, element: <AddUpdateSurvey /> },
      { path: `${commonVarialbleValue?.details}/:id`, element: <AddUpdateSurvey /> },
      { path: `${commonVarialbleValue?.ila}/:id`, element: <SurveyILA /> },
    ]
  },
  claim: {
    base: { path: commonVarialbleValue?.claim, element: <Outlet /> },
    children: [
      { path: '', element: <Claims /> },
      { path: commonVarialbleValue?.add, element: <AddUpdateClaims /> },
      { path: `${commonVarialbleValue?.update}/:id`, element: <AddUpdateClaims /> },
      { path: `${commonVarialbleValue?.details}/:id`, element: <AddUpdateClaims /> },
    ]
  },
};

const generateRoutes = (permissions, routeConfigurations, name) => {
  const routes = [];

  const addRoutes = (config, permission) => {
    // console.log("permission", permission)
    let children = config?.children?.filter(child => {
      const action = child?.path?.split('/')[0] || 'list';
      if (child?.children && child?.children?.length > 0) {
        return addRoutes({ children: child.children }, permission)
      }
      if (action === "details") return permission["read"]
      if (action === "details") return permission["read"] ? child : { ...child, element: (<PermissionGuard permission={permission["read"]}>{child?.element}</PermissionGuard>) };
      if (action === "list") {
        if (permission["read"]) {
          return permission["read"] ? child : { ...child, element: (<PermissionGuard permission={permission["read"]}>{child?.element}</PermissionGuard>) };
        } else {

        }
      }
      if (action === "list") return permission["read"] ? permission["read"] : permission["list"];
      if (action === "list") return permission["read"] ? permission["read"] : permission["list"];
      return action === "ila" ? true : permission[action];
    });
    return children;
  };
  permissions?.forEach((permission) => {
    const config = routeConfigurations[permission?.title];
    if (config) {
      routes.push({
        ...config.base,
        children: addRoutes(config, permission),
      });
    }
  });
  return routes;
};

export default function Router() {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(authActions?.handleRefresh())
  }, [])

  let token = localStorage.getItem("token") || "";

  const { role, permissions } = useMemo(() => parseToken(token), [token]);

  console.log("permissions", permissions);

  useEffect(() => {
    if (permissions && permissions?.length > 0) {
      dispatch(setTokenPermissionsData(permissions))
    }
  }, [permissions])

  const authenticatedAllRoutes = useMemo(() => generateRoutes(permissions, routeConfigurations, "superadmin"), [permissions]);
  const permissionAccessedCompnayAllRoutes = useMemo(() => generateRoutes(permissions, compnayPermissionRouterConfig, "compnayadmin"), [permissions]);

  // console.log("authenticatedAllRoutes", authenticatedAllRoutes);

  const routes = useRoutes([
    {
      element: (
        <AuthGuard>
          <DashboardLayout>
            <Suspense fallback={<LoadingScreen />}>
              <Outlet />
            </Suspense>
          </DashboardLayout>
        </AuthGuard>
      ),
      children: [
        {
          path: '',
          element: <Outlet />,
          children: [
            { path: '', element: <AuthenticatedIndexPage />, index: true },
            ...authenticatedAllRoutes,
          ]
        },
        {
          path: 'profile', element: <Outlet />,
          children: [
            { path: '', element: <ProfilePage /> },
            { path: `${commonVarialbleValue?.update}/:email`, element: <UpdateProfileView /> },
          ]
        },
        {
          path: commonVarialbleValue?.companyAdmin, element: <Outlet />,
          children: [
            { path: '', element: <CompanyAdminDashboard /> },
            ...permissionAccessedCompnayAllRoutes,
          ]
        },
        {
          path: commonVarialbleValue?.brokerAdmin, element: <Outlet />,
          children: [
            { path: '', element: <BrokerAdminDashboard /> },
            {
              path: commonVarialbleValue?.notifications, element: <Outlet />,
              children: [
                { path: '', element: <Notifications /> },
              ]
            },
          ]
        },
        {
          path: commonVarialbleValue?.lossAssessorAdmin, element: <Outlet />,
          children: [
            { path: '', element: <LossAssesorAdminDashboard /> },
            {
              path: commonVarialbleValue?.survey,
              element: <Outlet />,
              children: [
                { path: '', element: <Survey /> },
                { path: commonVarialbleValue?.add, element: <AddUpdateSurvey /> },
                { path: `${commonVarialbleValue?.update}/:id`, element: <AddUpdateSurvey /> },
                { path: `${commonVarialbleValue?.details}/:id`, element: <AddUpdateSurvey /> },
              ]
            },
            { path: '', element: <LossAssesorAdminDashboard /> },
            { path: commonVarialbleValue?.calendar, element: <h1>Calendar comming soon</h1> },
            {
              path: commonVarialbleValue?.claim, element: <Outlet />,
              children: [
                { path: '', element: <Claims /> },
                { path: commonVarialbleValue?.add, element: <AddUpdateClaims /> },
                { path: `${commonVarialbleValue?.update}/:id`, element: <AddUpdateClaims /> },
                { path: `${commonVarialbleValue?.details}/:id`, element: <AddUpdateClaims /> },
              ]
            },
            {
              path: commonVarialbleValue?.notifications, element: <Outlet />,
              children: [
                { path: '', element: <Notifications /> },
              ]
            },
          ]
        },
        {
          path: commonVarialbleValue?.policyOwnerAdmin, element: <Outlet />,
          children: [
            { path: '', element: <PolicyOwnerAdminDashboard /> },
            {
              path: commonVarialbleValue?.notifications, element: <Outlet />,
              children: [
                { path: '', element: <Notifications /> },
              ]
            },
          ]
        },
      ],
    },
    {
      element: (
        <GuestGuard>
          <Outlet />
        </GuestGuard>
      ),
      children: [
        { path: '', element: <UnauthenticatedIndexPage />, index: true },
        { path: 'login', element: <UnauthenticatedIndexPage /> },
        { path: 'forgot-password', element: <ForgotPassword /> },
        { path: 'reset-password/:id/:token', element: <ResetPassword /> },
        { path: 'ResetPassword/:id/:token', element: <ResetPassword /> },
      ],
    },
    { path: '404', element: <Page404 /> },
    { path: '*', element: <Page404 /> },
  ]);

  return routes;
}


function PermissionGuard({ children, permission }) {
  debugger
  const navigate = useNavigate();

  useEffect(() => {
    if (!permission) {
      toast.warning('You do not have permission to access this page.');
      navigate(-1); // Go back to the previous page
    }
  }, [permission, navigate]);

  return permission ? children : null;
};
