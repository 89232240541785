import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import { server } from "src/constant/axios_config";
import { baseURL } from "src/constant/contant";
import updateObject from "src/utils/updateObject";

// ******* User CRUD *********

export const createUser = createAsyncThunk("create/user", async (userData, { rejectWithValue }) => {
  try {
    const response = await server.post("register", userData);
    return response?.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

//update userData here
export const UpdateUser = createAsyncThunk("update/user", async (userData, { rejectWithValue }) => {
  try {
    const response = await server.patch(`user/update/${userData.id}`, userData);
    return response?.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});


//update userData here
export const dashboard = createAsyncThunk("dashboard", async (userData, { rejectWithValue }) => {
  try {
    const response = await server.get(`dashboard`);
    return response?.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});


//update userData here
export const dashboardExport = createAsyncThunk("dashboard/export", async (data, { rejectWithValue }) => {
  try {
    const response = await server.get(`/export-dashboard?format=csv`);
    return response?.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});


//update userData here
export const exportAllData = createAsyncThunk("export/all/data", async (data, { rejectWithValue }) => {
  try {
    const response = await server.get(`/export/${data?.module}/${data?.format}`);
    return response?.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const profilePictureUpload = createAsyncThunk("upload/profle_picture", async (companyData, { rejectWithValue }) => {
  try {
    const response = await server.post("upload-image?type=profile", companyData, { headers: { 'Content-Type': 'multipart/form-data' } });
    toast.success("Image uploaded successfully")
    return response?.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

//fetch user data
export const fetchUser = createAsyncThunk("fetch/user", async (payload, { rejectWithValue }) => {
  try {
    const { page = 0, pageSize = 5, searchName = null, sortedByKey = 'created_at', ascending = true, user_type = "", company_id = "" } = payload;
    let url = `user/list?page=${page + 1 || ""}&page_size=${pageSize || ""}&name=${searchName || ""}&sort_by=${sortedByKey}&ascending=${ascending}${user_type ? `&user_type=${user_type}` : ''}`
    if (company_id) url += `&company_id=${company_id || ""}`
    const response = await server.get(url);
    return response?.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

//update user status
export const updateUserStatus = createAsyncThunk("update/user/status", async (payload, { rejectWithValue }) => {
  try {
    const response = await server.put(`user/status/${payload?.id}?status=${payload?.data?.status}`);
    return response?.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

//fetch user by email
export const fetchUserByEmail = createAsyncThunk("fetch/user/email", async (email, { rejectWithValue }) => {
  try {
    const response = await server.get(`user/${email}`);
    return response?.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

//Deleate userData here
export const DeleteUser = createAsyncThunk("dDeleteUser/data", async (id, { rejectWithValue }) => {
  try {
    const response = await server.delete(`user/delete/${id}`);
    return response?.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const updateUserProfile = createAsyncThunk(
  'user/updateUserProfile',
  async ({ id, profile_image }, { rejectWithValue }) => {
    try {
      const response = await server.patch(`user/update/${id}`, { profile_image });
      console.log("this will image update", response.data)
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Upload image file
export const uploadImageFile = createAsyncThunk(
  "upload/image",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await server.post(
        `${baseURL}upload-image?type=profile`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("this will image update", response.data)
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Upload files
export const uploadDocument = createAsyncThunk(
  "upload/document",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await server.post(
        `${baseURL}upload-document`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// ******* Broker CRUD *********

// create Broker company
export const createBroker = createAsyncThunk("create/broker", async (brokerData, { rejectWithValue }) => {
  try {
    const response = await server.post("brokers", brokerData);
    return response?.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

// fetch Broker compnay data
export const fetchBrokerCompany = createAsyncThunk("fetch/broker/compnay", async (payload, { rejectWithValue }) => {
  try {
    const { page = 0, pageSize = 5, searchName = null, sortedByKey = 'created_at', ascending = true, user_id = "" } = payload;
    let url = `brokers/${user_id}/companies?page=${page + 1 || ""}&page_size=${pageSize || ""}&sort_by=${sortedByKey}&ascending=${ascending}`
    const response = await server.get(url);
    return response?.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

//Deleate Broker Company
export const deleteBrokerCompany = createAsyncThunk("delete/broker/company", async (id, { rejectWithValue }) => {
  try {
    const response = await server.delete(`brokers/${id}`);
    return response?.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

// ******* Loss Assesor CRUD *********

// create Loss Assesor company
export const createLossAssesor = createAsyncThunk("create/lossAssesor", async (lossAssesorData, { rejectWithValue }) => {
  try {
    const response = await server.post("loss-assessors", lossAssesorData);
    return response?.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

// fetch Loss Assesor compnay data
export const fetchLossAssesorCompany = createAsyncThunk("fetch/lossAssesor/compnay", async (payload, { rejectWithValue }) => {
  try {
    const { page = 0, pageSize = 5, searchName = null, sortedByKey = 'created_at', ascending = true, user_id = "" } = payload;
    let url = `loss-assessors/${user_id}/companies?page=${page + 1 || ""}&page_size=${pageSize || ""}&sort_by=${sortedByKey}&ascending=${ascending}`
    const response = await server.get(url);
    return response?.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

//Deleate Loss Assesor Company
export const deleteLossAssesorCompany = createAsyncThunk("delete/lossAssesor/company", async (id, { rejectWithValue }) => {
  try {
    const response = await server.delete(`loss-assessors/${id}`);
    return response?.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

// ******* User Insurance CRUD *********



// create User Insurance company
export const createUserInsurance = createAsyncThunk("create/user/insurance", async (userInsuranceData, { rejectWithValue }) => {
  try {
    const response = await server.post("user-insurances", userInsuranceData);
    return response?.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

// fetch User Insurance compnay data
export const fetchUserInsurance = createAsyncThunk("fetch/user/insurance", async (payload, { rejectWithValue }) => {
  try {
    const { page = 0, pageSize = 5, searchName = null, sortedByKey = 'created_at', ascending = true, user_id = "", insurance_id = "" } = payload;
    let url = `user-insurances?page=${page + 1 || ""}&page_size=${pageSize || ""}&sort_by=${sortedByKey}&ascending=${ascending}&user_id=${user_id || ""}`
    const response = await server.get(url);
    return response?.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

//Deleate User Insurance Company
export const deleteUserInsurance = createAsyncThunk("delete/user/insurance", async (id, { rejectWithValue }) => {
  try {
    const response = await server.delete(`user-insurances/${id}`);
    return response?.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

// ******* Claim CRUD *********

// create Claim
export const createClaim = createAsyncThunk("create/Claim", async (userClaimData, { rejectWithValue }) => {
  try {
    const response = await server.post("claims", userClaimData);
    return response?.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

// fetch Claim
export const fetchClaimOld = createAsyncThunk("fetch/Claim", async (payload, { rejectWithValue }) => {
  try {
    const { page = 0, pageSize = 5, searchName = null, sortedByKey = 'created_at', ascending = false, user_id = "", insurance_id = "" } = payload;
    let url = `claims?page=${page + 1 || ""}&page_size=${pageSize || ""}&sort_by=${sortedByKey}&ascending=${ascending}&user_id=${user_id || ""}`
    const response = await server.get(url);
    return response?.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});


// fetch Claim
export const fetchClaim = createAsyncThunk("fetch/Claim", async (payload, { rejectWithValue }) => {
  try {
    const { page = 0, pageSize = 5, searchName = null, insurance_type = [],
      start_Date = null, end_Date = null, sortedByKey = 'created_at',
      ascending = true, status = null, insurance_id = null, broker_id = null, loss_assessor_id = null, person_id = null } = payload;

    const response = await server.post("claims-get ", {
      "start_Date": start_Date,
      "end_Date": end_Date,
      "broker_id": broker_id,
      "loss_assessor_id": loss_assessor_id,
      "status": status,
      "company": null,
      "insurance_type": insurance_type,
      "claim_number": null,
      "claimed_by": null,
      "page": page + 1,
      "page_size": pageSize,
      "sort_by": sortedByKey,
      "ascending": ascending,
      "person_id": person_id,
      "insurance_id": insurance_id,
      "search": searchName
    });
    return response?.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

//fetch claim by id
export const fetchClaimById = createAsyncThunk("fetch/Claim/id", async (id, { rejectWithValue }) => {
  try {
    const response = await server.get(`claims/${id}`);
    return response?.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

//Delete Claim
export const deleteClaim = createAsyncThunk("delete/Claim", async (id, { rejectWithValue }) => {
  try {
    const response = await server.delete(`claims/${id}`);
    return response?.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

//update Claim
export const UpdateClaim = createAsyncThunk("update/Claim", async (userClaimData, { rejectWithValue }) => {
  try {
    const response = await server.patch(`claims/${userClaimData.id}`, userClaimData);
    return response?.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});


//upload Claim document
export const uploadClaimDocument = createAsyncThunk("upload/Claim/Document", async (data, { rejectWithValue }) => {
  try {
    const response = await server.post(`/claim-documents`, data);
    return response?.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});


//delete Claim document
export const deleteClaimDocument = createAsyncThunk("delete/Claim/Document", async (data, { rejectWithValue }) => {
  try {
    const response = await server.delete(`/claim-documents/${data?.id}`, data);
    return response?.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});




//upload Claim invoice
export const uploadClaimInvoice = createAsyncThunk("upload/Claim/Invoice", async (data, { rejectWithValue }) => {
  try {
    const response = await server.post(`/invoice`, data);
    return response?.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});



//download Image Pdf
export const downloadImagePDF = createAsyncThunk("download/Image/PDF", async (data, { rejectWithValue }) => {
  try {
    const response = await server.get(`/download-images?survey_id=${data?.survey_id}`);
    return response?.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});


//delete Invoice invoice
export const deleteClaimInvoice = createAsyncThunk("delete/Claim/Invoice", async (data, { rejectWithValue }) => {
  try {
    const response = await server.delete(`/invoice/${data?.id}`, data);
    return response?.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});




//***** surveys crud *****
//feach Surveys here

export const feachSurveys = createAsyncThunk("fetch/surveys", async (payload, { rejectWithValue }) => {
  try {
    const response = await server.post(`/surveys-get`, payload?.data);
    return response?.data
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

// create SurveysUser here

export const createSurveysUser = createAsyncThunk("create/surveys", async (userData, { rejectWithValue }) => {
  try {
    const response = await server.post("surveys", userData);
    return response?.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

//update SurveysUser here

export const UpdateSurveysUser = createAsyncThunk("update/surveys", async (userData, { rejectWithValue }) => {
  try {
    const response = await server.patch(`surveys/${userData.id}`, userData);
    return response?.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

//get Surveyrs ById
export const getSurveyrsById = createAsyncThunk("/surveys", async (getData, { rejectWithValue }) => {
  try {
    const response = await server.get(`surveys/${getData}`);
    return response?.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

// post survey document
export const postSurveyDocument = createAsyncThunk("post/surveys/document", async (documentData, { rejectWithValue }) => {
  try {
    const response = await server.post(`survey-documents`, documentData);
    return response?.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});


//  request document
export const requestSurveyDocument = createAsyncThunk("request/surveys/document", async (documentData, { rejectWithValue }) => {
  try {
    const response = await server.post(`document-request/`, documentData);
    return response?.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const fetchSurveyDocument = createAsyncThunk("fetch/surveys/doucment", async (payload, { rejectWithValue }) => {
  try {
    const { ascending = true, sortedByKey = 'created_at', page = 0, pageSize = 5, survey_id = "" } = payload;
    let url = `/survey-documents?`;
    url = url + `page=${page + 1 || ""}&page_size=${pageSize || ""}&sort_by=${sortedByKey}&ascending=${ascending}`
    if (survey_id) url += `&survey_id=${survey_id}`
    const response = await server.get(url);
    return response?.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const deleteSurveyDocument = createAsyncThunk("delete/surveys/doucment", async (id, { rejectWithValue }) => {
  try {
    const response = await server.delete(`survey-documents/${id}`);
    return response?.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

//update SurveysUser here
export const updateSurveyDocument = createAsyncThunk("update/surveys/doucment", async (documentData, { rejectWithValue }) => {
  try {
    const response = await server.patch(`survey-documents/${documentData.id}`, documentData);
    return response?.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});


//Create  Survey ILA
export const createSurveyILA = createAsyncThunk("survey/ila", async (surveyILAData, { rejectWithValue }) => {
  try {
    const response = await server.post(`ila`, surveyILAData);
    return response?.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});


//get Survey ILA ById
export const getSurveyILA = createAsyncThunk("create/survey/ila", async (data, { rejectWithValue }) => {
  try {
    var url = `ila?`;
    if (data?.survey_id) url = url + `survey_id=${data?.survey_id}`
    const response = await server.get(url);
    return response?.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});


//Update  Survey ILA
export const updateSurveyILA = createAsyncThunk("update/survey/ila", async (surveyILAData, { rejectWithValue }) => {
  try {
    const response = await server.patch(`ila/${surveyILAData?.id}`, surveyILAData);
    return response?.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});


//Update  Survey ILA
export const sendSurveyILA = createAsyncThunk("send/survey/ila", async (data, { rejectWithValue }) => {
  try {
    var url = `ila/send/${data?.id}?`;
    if (data?.type) url = url + `ila_type=${data?.type}`
    const response = await server.get(url);
    return response?.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});



//Update  Survey LOR
export const sendSurveyLOR = createAsyncThunk("send/survey/lor", async (data, { rejectWithValue }) => {
  try {
    const response = await server.get(`lor/send/${data?.id}`);
    return response?.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});




//Send Survey FSR
export const sendSurveyFSR = createAsyncThunk("send/survey/fsr", async (data, { rejectWithValue }) => {
  try {
    const response = await server.get(`fsr/send/${data?.id}`);
    return response?.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});


//slices creation for users Data
export const userDetail = createSlice({
  name: "userDetail",
  initialState: {
    users: [],
    brokerCompany: [],
    lossAssesorCompany: [],
    userInsurances: [],
    claimData: [],
    singleUser: null,
    loading: false,
    error: null,
    imageUploadSuccess: false,
    imageUploadError: null,
    Survey: [],
    ServeyourUsers: [],
    updateServeyorUser: [],
    getSurveyrsDataById: [],
    postSurveyDocuments: [],
  },
  reducers: {
    setSingleUser: (state, action) => {
      state.singleUser = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      // Image upload
      .addCase(uploadImageFile.pending, (state) => {
        state.imageUploadSuccess = false;
        state.imageUploadError = null;
      })
      .addCase(uploadImageFile.fulfilled, (state, action) => {
        state.imageUploadSuccess = true;
        // Handle the successful response if needed
        toast.success("image has been uploaded")
        console.log(action.payload);
      })
      .addCase(uploadImageFile.rejected, (state, action) => {
        state.imageUploadSuccess = false;
        state.imageUploadError = action.payload;
        toast.error("Something Wrong")
        // Handle the error if needed
        console.error(action.payload);
      })

      // Document upload
      .addCase(uploadDocument.pending, (state) => {
      })
      .addCase(uploadDocument.fulfilled, (state, action) => {
        // Handle the successful response if needed
        // toast.success("Document has been uploaded")
      })
      .addCase(uploadDocument.rejected, (state, action) => {
        toast.error("Something Wrong")
      })

      // ******* User CRUD *********

      // create user
      .addCase(createUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(createUser.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.success) {
          state?.users?.list?.push(action?.payload?.data);
          toast.success('User Created Successfully');
        }
      })
      .addCase(createUser.rejected, (state, action) => {
        state.loading = false;
      })

      // fetch all user
      .addCase(fetchUser.pending, (state) => {
        state.loading = true;
        state.users = []
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.loading = false;
        state.users = action.payload?.data;
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.loading = false;
        state.error = false;
      })


      // fetch user by email
      .addCase(fetchUserByEmail.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUserByEmail.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.success && state?.users?.list?.length) {
          let updateData = [...state?.users?.list];
          updateData = updateData?.map((el) => el?.id === action?.payload?.data?.id ? action?.payload?.data : el);
          state.users.list = updateData
        }
      })
      .addCase(fetchUserByEmail.rejected, (state, action) => {
        state.loading = false;
      })

      // delete user
      .addCase(DeleteUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(DeleteUser.fulfilled, (state, action) => {
        state.loading = false;
        const { id } = action.payload?.data;
        if (id) {
          state.users = state.users.filter((ele) => ele.id !== id);
        }
        toast.success("user Deleted Successfully")
      })
      .addCase(DeleteUser.rejected, (state, action) => {
        state.loading = false;
      })

      // update user
      .addCase(UpdateUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(UpdateUser.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.success) {

        }
      })
      .addCase(UpdateUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })

      // update user status
      .addCase(updateUserStatus.fulfilled, (state, action) => {
        if (action?.payload?.success) {
          toast.success("User Status Has been updated");
          const updatedUsers = state.users.list.map((user) => {
            if (user.id === action.payload.data.id) {
              return updateObject(user, { status: action.payload.data.status });
            }
            return user;
          });
          state.users.list = updatedUsers;
        }
      })
      .addCase(updateUserStatus.rejected, (state, action) => {
        // Handle the error if needed
        toast.error("Something Wrong")
      })
      .addCase(updateUserProfile.pending, (state) => {
        state.loading = true;

      })
      .addCase(updateUserProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.currentUser = action.payload;
        console.log("state.currentUser", state.currentUser)
        toast.success("Image Has Been Successfully Updated")
      })
      .addCase(updateUserProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        toast.error("Something Wrong")
      })

      // ******* Broker CRUD *********

      // create Broker
      .addCase(createBroker.pending, (state) => {
        state.loading = true;
      })
      .addCase(createBroker.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.success) {
          toast.success('Broker Company Added Successfully');
        } else {
          toast.error(action?.payload?.message);
        }
      })
      .addCase(createBroker.rejected, (state, action) => {
        state.loading = false;
      })

      // fetch Broker compnay data
      .addCase(fetchBrokerCompany.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchBrokerCompany.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.success) {
          state.brokerCompany = action?.payload?.data
          // toast.success('Broker Company Added Successfully');
        }
      })
      .addCase(fetchBrokerCompany.rejected, (state, action) => {
        state.loading = false;
      })

      // delete Broker compnay data
      .addCase(deleteBrokerCompany.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteBrokerCompany.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.success) {
          toast.success('Broker Company Deleted Successfully');
        } else {
          toast.error(action?.payload?.message);
        }
      })
      .addCase(deleteBrokerCompany.rejected, (state, action) => {
        state.loading = false;
      })

      // ******* Loss Assesor CRUD *********

      // create Loss Assesor
      .addCase(createLossAssesor.pending, (state) => {
        state.loading = true;
      })
      .addCase(createLossAssesor.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.success) {
          toast.success('Loss Assesor Company Added Successfully');
        } else {
          toast.error(action?.payload?.message);
        }
      })
      .addCase(createLossAssesor.rejected, (state, action) => {
        state.loading = false;
      })

      // fetch Loss Assesor compnay data
      .addCase(fetchLossAssesorCompany.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchLossAssesorCompany.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.success) {
          state.lossAssesorCompany = action?.payload?.data
          // toast.success('Loss Assesor Company Added Successfully');
        }
      })
      .addCase(fetchLossAssesorCompany.rejected, (state, action) => {
        state.loading = false;
      })

      // delete Loss Assesor compnay data
      .addCase(deleteLossAssesorCompany.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteLossAssesorCompany.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.success) {
          toast.success('Loss Assesor Company Deleted Successfully');
        } else {
          toast.error(action?.payload?.message);
        }
      })
      .addCase(deleteLossAssesorCompany.rejected, (state, action) => {
        state.loading = false;
      })


      // ******* User Insurance CRUD *********

      // create User Insurance
      .addCase(createUserInsurance.pending, (state) => {
        state.loading = true;
      })
      .addCase(createUserInsurance.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.success) {
          toast.success('User Insurance Added Successfully');
        } else {
          toast.error(action?.payload?.message);
        }
      })
      .addCase(createUserInsurance.rejected, (state, action) => {
        state.loading = false;
      })

      // fetch User Insurance compnay data
      .addCase(fetchUserInsurance.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUserInsurance.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.success) {
          state.userInsurances = action?.payload?.data
          // toast.success('User Insurance Company Added Successfully');
        }
      })
      .addCase(fetchUserInsurance.rejected, (state, action) => {
        state.loading = false;
      })

      // delete User Insurance compnay data
      .addCase(deleteUserInsurance.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteUserInsurance.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.success) {
          toast.success('User Insurance Deleted Successfully');
        } else {
          toast.error(action?.payload?.message);
        }
      })
      .addCase(deleteUserInsurance.rejected, (state, action) => {
        state.loading = false;
      })

      // ******* Claim CRUD *********

      // create Claim
      .addCase(createClaim.pending, (state) => {
        state.loading = true;
      })
      .addCase(createClaim.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.success) {
          toast.success('Claim Added Successfully');
        } else {
          toast.error(action?.payload?.message);
        }
      })
      .addCase(createClaim.rejected, (state, action) => {
        state.loading = false;
      })

      // fetch Claim
      .addCase(fetchClaim.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchClaim.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.success) {
          state.claimData = action?.payload?.data
          // toast.success('Claim Company Added Successfully');
        }
      })
      .addCase(fetchClaim.rejected, (state, action) => {
        state.loading = false;
      })

      // Update Claim
      .addCase(UpdateClaim.pending, (state) => {
        state.loading = true;
      })
      .addCase(UpdateClaim.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.success) {
          toast.success('Claim Updated Successfully');
        } else {
          toast.error(action?.payload?.message);
        }
      })
      .addCase(UpdateClaim.rejected, (state, action) => {
        state.loading = false;
      })

      // delete Claim
      .addCase(deleteClaim.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteClaim.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.success) {
          toast.success('Claim Deleted Successfully');
        } else {
          toast.error(action?.payload?.message);
        }
      })
      .addCase(deleteClaim.rejected, (state, action) => {
        state.loading = false;
      })


      // ******* Surveys CRUD *********
      // surveys feach case 
      .addCase(feachSurveys.pending, (state) => {
        state.loading = true;
      })
      .addCase(feachSurveys.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.success) {
          state.Survey = action?.payload?.data
          // toast.success('Broker Company Added Successfully');
        } else {
          state.Survey = []
        }
      })
      .addCase(feachSurveys.rejected, (state, action) => {
        state.loading = false;
      })

      // add createSurveysUser

      .addCase(createSurveysUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(createSurveysUser.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.success) {
          state?.ServeyourUsers?.list?.push(action?.payload?.data);
          toast.success('Loss Assessor assigned successfully.');
        }
      })
      .addCase(createSurveysUser.rejected, (state, action) => {
        state.loading = false;
        toast.error(action?.payload?.message);
      })
      // update createSurveysUser

      .addCase(UpdateSurveysUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(UpdateSurveysUser.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.success) {
          state?.updateServeyorUser?.list?.push(action?.payload?.data);
          toast.success('Survey updated successfully');
        }
      })
      .addCase(UpdateSurveysUser.rejected, (state, action) => {
        state.loading = false;
      })

      // get Surveyrs By Id 
      .addCase(getSurveyrsById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSurveyrsById.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.success) {
          state?.getSurveyrsDataById?.list?.push(action?.payload?.data);
        }
      })
      .addCase(getSurveyrsById.rejected, (state, action) => {
        state.loading = false;
      })

      //post survey document
      .addCase(postSurveyDocument.pending, (state) => {
        state.loading = true;
      })
      .addCase(postSurveyDocument.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.success) {
          state?.postSurveyDocuments?.list?.push(action?.payload?.data);
          toast.success('Document uploaded successfully');
        }
      })
      .addCase(postSurveyDocument.rejected, (state, action) => {
        state.loading = false;
      })

      //request survey document
      .addCase(requestSurveyDocument.pending, (state) => {
        state.loading = true;
      })
      .addCase(requestSurveyDocument.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.success) {
          state?.postSurveyDocuments?.list?.push(action?.payload?.data);
          toast.success('Document requested successfully');
        }
      })
      .addCase(requestSurveyDocument.rejected, (state, action) => {
        state.loading = false;
      })
  },
});

export default userDetail.reducer;
export const userAction = userDetail?.actions;