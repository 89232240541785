import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import { baseURL } from "src/constant/contant";
export const downloadFile = createAsyncThunk(
  "download/file",
  async ({ module, format }, { rejectWithValue }) => {
    try {
      const url1 = `${baseURL}export/${module}/${format}`;
      const response = await fetch(url1);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      console.log("this is a url response", url)
      return url;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  fileUrl: null,
  loading: false,
  error: null,
};

const downloadSlice = createSlice({
  name: "download",
  initialState,
  reducers: {
    resetDownload: (state) => {
      state.fileUrl = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(downloadFile.pending, (state) => {
        state.loading = true;
      })
      .addCase(downloadFile.fulfilled, (state, action) => {
        state.loading = false;
        state.fileUrl = action.payload;
        toast.success("File Has Been Downloaded")
      })
      .addCase(downloadFile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        toast.error("Something Wrong")
      });
  },
});

export const { resetDownload } = downloadSlice.actions;
export default downloadSlice.reducer;