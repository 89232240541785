import { Box, Breadcrumbs, Typography } from '@mui/material'
import React from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import Iconify from 'src/components/iconify'

const PageTitle = ({ title }) => {
    const location = useLocation();
    let params = useParams();
    params = Object.values(params)

    var pathnames = location.pathname.split('/').filter((x) => x);
    pathnames = pathnames.filter((x) => !params?.includes(x));

    return (
        <Box>
            <Typography variant="h4">{title}</Typography>
            <Breadcrumbs aria-label="breadcrumb" separator={<Iconify icon="eva:arrow-ios-forward-outline" sx={{ width: 16, verticalAlign: "sub" }} />}>
                {
                    pathnames?.length > 1 && pathnames?.map((value, index) => {
                        const to = `/${pathnames.slice(0, index + 1).join('/')}`;
                        return !value.includes("user") && (
                            index === pathnames?.length - 1
                                ? <Typography textTransform={"capitalize"} fontSize={14} color="text.primary" >{value}</Typography>
                                : <Link style={{ textDecoration: "none",}}   to={to} > <Typography textTransform={"capitalize"} sx={{ ":hover": { textDecoration: "underline" } }} fontSize={14} color={"#212b36"} >{value} </Typography></Link>
                        )
                    })
                }
            </Breadcrumbs>
            {/* <BasicBreadcrumbs breadcrumb={breadcrumb} /> */}
        </Box>
    )
}

export default PageTitle