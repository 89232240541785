import axios from 'axios';
import { baseURL } from './contant';

const getToken = () => {
    let token = localStorage.getItem("token") || null
    return token ? token : ""
}


// Default config options
export const defaultOptions = {
    withCredentials: false,
    headers: {
        Accept: '*/*',
        "Content-Type": 'application/json'
    },
};

// Create instance
const server = axios.create({
    baseURL: baseURL,
    // baseURL: "http://127.0.0.1:8000/",
    timeout: 100000,
    ...defaultOptions
});

export async function authorizeMe(token) {
    server.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

// server?.interceptors.response.use(undefined, (err) => {
//     if (
//         (typeof err === 'string' && err?.toLowerCase()?.includes('unauthorized')) ||
//         (err?.message && err?.message?.toLowerCase()?.includes('unauthorized')) ||
//         (err?.response?.data?.message && err?.response?.data?.message?.toLowerCase()?.includes('unauthorized'))
//     ) {
//         signOut(() => {
//             if (window !== undefined) location.reload();
//         });
//     }
// });

server.interceptors.response.use(function (response) {
    response.headers.Authorization = `Bearer ${getToken()}`
    return response;
}, function (error) {
    if (error?.response?.status === 500) {
        // console.log("error?.response?.status", error?.response?.status);
        // toast.error("Interal server error")
    }
    if (error?.response?.status === 401) {
        // console.log("error?.response?.status", error?.response?.status);
        // const cookies = Cookies.get();

        // // Iterate over each cookie and remove it
        // Object.keys(cookies).forEach(cookieName => {
        //     Cookies.remove(cookieName);
        // });
        localStorage.clear();
        sessionStorage.clear();
        // window.location.pathname = "/#/"
    }
    return Promise.reject(error);
});

export { server };
