import { createAsyncThunk } from '@reduxjs/toolkit';
import { server, defaultOptions } from 'src/constant/axios_config';

export const forgotPasswords = createAsyncThunk(
  'auth/forgotPasswords',
  async (email, { rejectWithValue }) => {
    try {
      const response = await server.post("reset-password", { email: email }, { ...defaultOptions })
      return response?.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const resetPasswords = createAsyncThunk(
  'auth/resetPassword',
  async ({ token, id, newPassword }, { rejectWithValue }) => {
    try {
      const response = await server.post(`reset-password/redirect/${id}/${token}`, { newPassword: newPassword }, { ...defaultOptions })
      return response?.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);