import { createSlice } from '@reduxjs/toolkit';
import { getMe, login, register } from './authThunk';
import { toast } from 'react-toastify';
import { authorizeMe } from 'src/constant/axios_config';
import { companyAction } from '../company/companySlice';
import { roles, routesPath } from 'src/constant/contant';


const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: null,
    isLoading: false,
    error: null,
    isLoggedIn: null,
    currentUser: null,
    role: "",
    permissions: [],
  },
  reducers: {
    // Add any other reducers you might need
    handleRefresh: (state) => {
      let token = localStorage.getItem("token") || "";
      let user = localStorage.getItem("user") || null;
      if (token && token !== "undefined" && user) {
        state.isLoggedIn = true;
        state.user = JSON.parse(user)
        authorizeMe(token);

        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        var tokendata = JSON.parse(jsonPayload);
        state.role = tokendata?.role;
        const grouped = {};
        tokendata?.permissions?.forEach(permission => {
          const [category, action] = permission.split('_');
          if (!grouped[category]) grouped[category] = {};
          grouped[category][action] = true;
        });
        state.permissions = Object.keys(grouped).map(category => ({ title: category, ...grouped[category] }));

        if (state?.user?.user_type === roles?.ROLE_COMPANY_ADMIN) {
          localStorage.setItem("companyId", state?.user?.company_id);
          window.location.href = `/#${routesPath?.companyAdmin?.dashboard}`;
          companyAction?.setCompanyAdminID(state?.user?.company_id);
        }
        if (state?.user?.user_type === roles?.ROLE_BROKER_ADMIN) {
          window.location.href = `/#${routesPath?.broker?.dashboard}`;
        }
        if (state?.user?.user_type === roles?.ROLE_LOSS_ASSESOR_ADMIN) {
          // window.location.href = `/#${routesPath?.lossAssesor?.dashboard}`;
        }
        if (state?.user?.user_type === roles?.ROLE_POLICY_OWNER_ADMIN) {
          window.location.href = `/#${routesPath?.policyOwner?.dashboard}`;
        }
      }
      else {
        state.isLoggedIn = false
      }
    }

  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.isLoggedIn = null;

      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action?.payload?.success) {
          state.user = action.payload?.data;
          state.isLoggedIn = true;
          localStorage.setItem("token", action.payload?.data?.token)
          localStorage.setItem("user", JSON.stringify(action.payload?.data))
          authorizeMe(action.payload?.data?.token)

          var base64Url = action.payload?.data?.token?.split('.')[1];
          var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
          var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          }).join(''));
          var tokendata = JSON.parse(jsonPayload);
          state.role = tokendata?.role;
          const grouped = {};
          tokendata?.permissions?.forEach(permission => {
            const [category, action] = permission.split('_');
            if (!grouped[category]) grouped[category] = {};
            grouped[category][action] = true;
          });
          state.permissions = Object.keys(grouped).map(category => ({ title: category, ...grouped[category] }));

          if (action?.payload?.data?.user_type === roles?.ROLE_COMPANY_ADMIN) {
            localStorage.setItem("companyId", action.payload?.data?.company_id);
            window.location.href = `/#${routesPath?.companyAdmin?.dashboard}`;
            companyAction?.setCompanyAdminID(action.payload?.data?.company_id);
          }
          if (action?.payload?.data?.user_type === roles?.ROLE_BROKER_ADMIN) {
            window.location.href = `/#${routesPath?.broker?.dashboard}`;
          }
          if (action?.payload?.data?.user_type === roles?.ROLE_LOSS_ASSESOR_ADMIN) {
            window.location.href = `/#${routesPath?.lossAssesor?.dashboard}`;
          }
          if (action?.payload?.data?.user_type === roles?.ROLE_POLICY_OWNER_ADMIN) {
            window.location.href = `/#${routesPath?.policyOwner?.dashboard}`;
          }
        } else {
          toast.error(action?.payload?.message)
        }
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
        state.isLoggedIn = false;
        toast.error("Invalid Credentials")
      })
      .addCase(register.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(register.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = action.payload?.data;
        state.isLoggedIn = true;
        localStorage.setItem("token", action.payload?.data?.token)
        localStorage.setItem("user", JSON.stringify(action.payload?.data))
      })
      .addCase(register.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
        state.isLoggedIn = false;
        toast.error("Invalid Credentials")
      })
      // current user me
      .addCase(getMe.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getMe.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action?.payload?.success) {
          state.currentUser = action.payload?.data;
        }
      })
      .addCase(getMe.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});


export default authSlice.reducer;
export const authActions = authSlice.actions;