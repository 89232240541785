import { configureStore } from '@reduxjs/toolkit';
import authReducer from "./auth/authSlice"
import userDetail from 'src/redux/user/userSlice';
import companyDetail from 'src/redux/company/companySlice';
import forgetPasswordSlice from 'src/redux/auth/forgetPasswordSlice';
import roleDetail from 'src/redux/roles&permission/roleSlice';
import permissionDetail from 'src/redux/roles&permission/permissionSlice';
import insurancetypeDetail from './insurancetype/InsuranceTypeSlice';
import downloadSlice from "./csvpdfFiledownload/csvFileSlice";
import insuranceDetails from './insurance/insuranceSlice';
import { documentReducer } from './document/documentSlice';
import notificationDetails from './notification/notificationSlice';

const store = configureStore({
  reducer: {
    auth: authReducer,
    user: userDetail,
    company: companyDetail,
    password: forgetPasswordSlice,
    role: roleDetail,
    permission: permissionDetail,
    insurancetype: insurancetypeDetail,
    download: downloadSlice,
    insurances: insuranceDetails,
    notification: notificationDetails,
    document: documentReducer
  },
});

export default store;