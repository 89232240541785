import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import { server } from "src/constant/axios_config";
import updateObject from "src/utils/updateObject";

export const createDocument = createAsyncThunk("create/documents", async (data, { rejectWithValue }) => {
  try {
    const response = await server.post("documents", data);
    return response?.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

//update userData here
export const UpdateDocument = createAsyncThunk("update/documents", async (data, { rejectWithValue }) => {
  try {
    const response = await server.patch(`documents/${data?.id}`, data);
    return response?.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

//fetch user data
export const fetchDocument = createAsyncThunk("fetch/documents", async (payload, { rejectWithValue }) => {
  try {
    const { page = 0, pageSize = 5, searchName = null, sortedByKey = 'title', ascending = true, user_type, is_mandatory = "", type_id = "" } = payload;
    let url = `documents?page=${page + 1 || ""}&page_size=${pageSize || ""}&title=${searchName || ""}&sort_by=${sortedByKey}&ascending=${ascending}`
    if (is_mandatory !== "") url += `&is_mandatory=${is_mandatory}`
    if (type_id) url += `&type_id=${type_id}`
    const response = await server.get(url);
    return response?.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});
//update user status
export const updateDocumentStatus = createAsyncThunk("update/documents/status", async (payload, { rejectWithValue }) => {
  try {
    const response = await server.put(`documents/status/${payload?.id}?status=${payload?.data?.status}`);
    return response?.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

//fetch user by email
export const fetchDocumentId = createAsyncThunk("fetch/documents/email", async (id, { rejectWithValue }) => {
  try {
    const response = await server.get(`documents/${id}`);
    return response?.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

//Deleate userData here
export const DeleteDocument = createAsyncThunk("dDeletedocuments/data", async (id, { rejectWithValue }) => {
  try {
    const response = await server.delete(`documents/delete/${id}`);
    return response?.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

//slices creation for users Data
export const documentDetail = createSlice({
  name: "documentDetail",
  initialState: {
    documents: [],
    loading: false,
    error: null,

  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // create user
      .addCase(createDocument.pending, (state) => {
        state.loading = true;
      })
      .addCase(createDocument.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.success) {
          state?.documents?.list?.push(action?.payload?.data);
          toast.success('Document Created Successfully');
        }
      })
      .addCase(createDocument.rejected, (state, action) => {
        state.loading = false;
      })

      // fetch all user
      .addCase(fetchDocument.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchDocument.fulfilled, (state, action) => {
        state.loading = false;
        state.documents = action.payload?.data;
      })
      .addCase(fetchDocument.rejected, (state, action) => {
        state.loading = false;
        state.error = false;
      })


      // fetch user by email
      .addCase(fetchDocumentId.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchDocumentId.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.success && state?.documents?.list?.length) {
          let updateData = [...state?.documents?.list];
          updateData = updateData?.map((el) => el?.id === action?.payload?.data?.id ? action?.payload?.data : el);
          state.documents.list = updateData
        }
      })
      .addCase(fetchDocumentId.rejected, (state, action) => {
        state.loading = false;
      })

      // delete user
      .addCase(DeleteDocument.pending, (state) => {
        state.loading = true;
      })
      .addCase(DeleteDocument.fulfilled, (state, action) => {
        state.loading = false;
        const { id } = action.payload?.data;
        if (id) {
          state.documents = state.documents.filter((ele) => ele.id !== id);
        }
        toast.success("Document Deleted Successfully")
      })
      .addCase(DeleteDocument.rejected, (state, action) => {
        state.loading = false;
      })

      // update user
      .addCase(UpdateDocument.pending, (state) => {
        state.loading = true;
      })
      .addCase(UpdateDocument.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.success) {
          toast.success('Document Updated Successfully');
        }
      })
      .addCase(UpdateDocument.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })

      // update user status
      .addCase(updateDocumentStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateDocumentStatus.fulfilled, (state, action) => {
        if (action?.payload?.success) {
          toast.success("Document Status Has been updated");
          const updatedDocument = state.documents.list.map((doc) => {
            if (doc.id === action.payload.data.id) {
              return updateObject(doc, { status: action.payload.data.status });
            }
            return user;
          });
          state.documents.list = updatedDocument;
        }
      })
      .addCase(updateDocumentStatus.rejected, (state, action) => {
        // Handle the error if needed
        toast.error("Something Wrong")
      })

  },
});

export const documentReducer = documentDetail.reducer;
export const documentAction = documentDetail?.actions;