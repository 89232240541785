import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { server } from "src/constant/axios_config";

//Create permissions by createAsyncThunk
export const CreatePermission = createAsyncThunk("create/permissions", async (data, { rejectWithValue }) => {
  try {
    const response = await server.post("permissions", data);
    return response?.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

//Read permissions by createAsyncThunk
export const ReadPermission = createAsyncThunk("read/permissions", async (data, { rejectWithValue }) => {
  //fetching data with api 1st we have to take console
  try {
    const response = await server.get("list/permission");
    return response?.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
})

//Update permissions by createAsyncThunk
export const UpdatePermission = createAsyncThunk("updates/permissions", async (data, { rejectWithValue }) => {
  //fetching data with api 1st we have to take it console
  try {
    const response = await server.patch(`permissions/${data.id}`, data);
    return response?.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
})

//delete permissions with createAsyncThunk
export const DeletePermission = createAsyncThunk("delete/permissions", async (id, { rejectWithValue }) => {
  //fetching data with api 1st we have to take it console
  try {
    const response = await server.delete(`permissions/${id}`);
    return response?.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

//slices creation for permission Data
export const permissionDetail = createSlice({
  name: "permission",
  initialState: {
    permission: [],
    permissionsData: [],
    loading: false,
    error: null,
    searchData: [],
    tokenPermissionsData: []
  },
  reducers: {
    setTokenPermissionsData: (state, action) => {
      state.tokenPermissionsData = action.payload;
    },
    searchPermission: (state, action) => {
      console.log(action.payload);
      state.searchData = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(CreatePermission.pending, (state) => {
        state.loading = true;
      })
      .addCase(CreatePermission.fulfilled, (state, action) => {
        state.loading = false;
        state.permission.push(action.payload);
      })
      .addCase(CreatePermission.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })
      .addCase(ReadPermission.pending, (state) => {
        state.loading = true;
      })
      .addCase(ReadPermission.fulfilled, (state, action) => {
        state.loading = false;
        state.permissionsData = action.payload
        state.permission = action.payload?.data?.map(item => ({
          label: item.permission,
          value: item.permission,
          description: item.description,
          is_internal: item.is_internal
        }));;
      })
      .addCase(ReadPermission.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(DeletePermission.pending, (state) => {
        state.loading = true;
      })
      .addCase(DeletePermission.fulfilled, (state, action) => {
        state.loading = false;
        const { id } = action.payload;
        if (id) {
          state.permission = state.permission.filter((ele) => ele.id !== id);
        }
      })
      .addCase(DeletePermission.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(UpdatePermission.pending, (state) => {
        state.loading = true;
      })
      .addCase(UpdatePermission.fulfilled, (state, action) => {
        state.loading = false;
        state.permission = state.permission.map((ele) =>
          ele.id === action.payload.id ? action.payload : ele
        );
      })
      .addCase(UpdatePermission.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      });
  },
});

export default permissionDetail.reducer;
export const { searchPermission, setTokenPermissionsData } = permissionDetail.actions;