import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Box, Stack, Drawer } from '@mui/material';
import { alpha } from '@mui/material/styles';
import ListItemButton from '@mui/material/ListItemButton';
import { usePathname } from 'src/routes/hooks';
import { RouterLink } from 'src/routes/components';
import { useResponsive } from 'src/hooks/use-responsive';
import Logo from '../../assets/logo/logo.png';
import Scrollbar from 'src/components/scrollbar';
import { NAV } from './config-layout';
import { navConfigCompany, navConfig, navConfigBroker, navConfigLossAssesor, navConfigPolicyOwner } from './config-navigation';
import { fetchUser } from 'src/redux/user/userSlice';
import { useSelector, useDispatch } from 'react-redux';
import AccountPopover from './common/account-popover';
import { Collapse, Divider, Typography } from '@mui/material';
import { fetchCompanyById } from 'src/redux/company/companySlice';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { commonVarialbleValue, imageUrl, roles, routesPath } from 'src/constant/contant';

export default function Nav({ openNav, onCloseNav }) {
  const pathname = usePathname();
  const dispatch = useDispatch()
  const upLg = useResponsive('up', 'lg');
  const { tokenPermissionsData } = useSelector(state => state?.permission)

  const { selectedCompany, companyAdminID } = useSelector((state) => state.company);
  const { user } = useSelector((state) => state.auth);
  useEffect(() => {
    if (companyAdminID) dispatch(fetchCompanyById(companyAdminID))
  }, [companyAdminID])

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
  }, [pathname]);

  useEffect(() => {
    dispatch(fetchUser())
  }, []);

  function matchPermission(path) {
    let pathList = path?.split("/")
    let find = tokenPermissionsData?.find((el) => {
      let title = el?.title
      if (el?.title === "policy_owner") title = "policy-owner"
      if (el?.title === "loss_assesor") title = "loss-assessor"
      if (el?.title === "dashboard_company_admin") title = "company-admin"
      return pathList?.includes(title)
    })
    return find
  }

  const renderMenu = (
    <Box justifyContent={'space-between'} display={'flex'} flexDirection={'column'} height={"100%"}>
      <Stack component="nav" spacing={0.5} sx={{ px: 2 }}>
        {
          (roles?.ROLE_SUPER_ADMIN === user?.user_type && !companyAdminID) && navConfig?.map((item) => (
            <NavItem key={item.title} item={item} />
          ))
        }
        {
          (roles?.ROLE_COMPANY_ADMIN === user?.user_type || companyAdminID) && navConfigCompany?.map((item) => (
            matchPermission(item?.path) && < NavItem key={item.title} item={item} />
          ))
        }
        {
          (roles?.ROLE_BROKER_ADMIN === user?.user_type) && navConfigBroker?.map((item) => (
            <NavItem key={item.title} item={item} />
          ))
        }
        {
          (roles?.ROLE_LOSS_ASSESOR_ADMIN === user?.user_type) && navConfigLossAssesor?.map((item) => (
            <NavItem key={item.title} item={item} />
          ))
        }
        {
          (roles?.ROLE_POLICY_OWNER_ADMIN === user?.user_type) && navConfigPolicyOwner?.map((item) => (
            <NavItem key={item.title} item={item} />
          ))
        }
      </Stack>
      <Stack component="nav" spacing={0.5} sx={{ px: 2, py: 2 }} >
        <ListItemButton
          href={'/profile'}
          component={RouterLink}
          sx={{
            minHeight: 44,
            borderRadius: 0.75,
            typography: 'body2',
            color: 'text.secondary',
            textTransform: 'capitalize',
            fontWeight: 'fontWeightMedium',
            ...(pathname === '/profile' && {
              color: 'primary.main',
              fontWeight: 'fontWeightSemiBold',
              bgcolor: (theme) => alpha(theme.palette.primary.main, 0.08),
              '&:hover': {
                bgcolor: (theme) => alpha(theme.palette.primary.main, 0.16),
              },
            }),
          }}
        >
          <AccountPopover />
        </ListItemButton>
      </Stack>
    </Box>
  );


  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
      }}   
    >
      {companyAdminID ?
        <>
          <div style={{ marginTop: "10px", display: "flex", justifyContent:"center", width: "100%",padding:"0px 16px",alignItems:"center" ,flexWrap:"wrap"}}>
            <img src={Logo} alt="" style={ { width: "35%", margin:"auto" }} />
            {selectedCompany?.logo ? <img src={imageUrl + selectedCompany?.logo} alt="" style={{  margin: "auto",  height: 40 ,objectFit:"contain"}} />
              :
              <Typography variant='h5' mx={2} fontSize={12} textAlign={"center"} fontWeight={600}>{selectedCompany?.name}</Typography>
            }
          </div>
        </> :

        <div width={160} height={70} style={{ marginTop: "10px", paddingLeft: "28px" }}>
          <img src={Logo} alt="" style={{ width: "82%" }} />
        </div>
      }
      {/* {renderAccount()}  */}
      {/* Call the function here */}
      <Divider sx={{ mb: 2,mt:1 }} />
      {renderMenu}

      <Box sx={{ flexGrow: 1 }} />

      {/* {renderUpgrade} */}
    </Scrollbar>
  );

  return (
    <Box
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.WIDTH },
        backgroundColor: "white"
      }}
    >
      {upLg ? (
        <Box
          sx={{
            height: 1,
            position: 'fixed',
            width: NAV.WIDTH,
            borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
          }}
        >
          {renderContent}
        </Box>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          PaperProps={{
            sx: {
              width: NAV.WIDTH,
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

// ----------------------------------------------------------------------

function NavItem({ item }) {
  var pathname = usePathname();

  const exactPath = pathname?.split("/")?.length > 0 ? `/${pathname?.split("/")?.[1]}` : pathname;
  const [open, setOpen] = useState(false);

  let active;
  if (pathname?.includes(commonVarialbleValue?.companyAdmin) || pathname?.includes(commonVarialbleValue?.lossAssessorAdmin)) {
    active = item.path === pathname;
  } else {
    active = item.path === pathname;
    if (!active) {
      active = item?.path === exactPath;
    }
  }

  // let active;
  // if (pathname?.includes(commonVarialbleValue?.companyAdmin) || pathname?.includes(commonVarialbleValue?.lossAssessorAdmin)) {
  //   active = item.path === pathname;
  // } else {
  //   var items = item.path.split('/')
  //   pathname = pathname.split("/")
  //   active = pathname[pathname?.length - 2] === items[items?.length - 1]
  //   if (!active) { active = item?.path === exactPath; }
  // }

  const handleToggle = () => {
    setOpen(!open);
  };

  const hasChildren = item?.children && item?.children.length > 0;

  return (
    <>
      <ListItemButton
        component={RouterLink}
        href={item.path}
        onClick={hasChildren ? handleToggle : null}
        // onClick={() => { console.log("click") }}
        sx={{
          minHeight: 44,
          borderRadius: 0.75,
          typography: 'body2',
          color: 'text.secondary',
          textTransform: 'capitalize',
          fontWeight: 'fontWeightMedium',
          ...(active && {
            color: 'primary.main',
            fontWeight: 'fontWeightSemiBold',
            bgcolor: (theme) => alpha(theme.palette.primary.main, 0.08),
            '&:hover': {
              bgcolor: (theme) => alpha(theme.palette.primary.main, 0.16),
            },
          }),
        }}
      >
        {item.icon && <Box component="span" sx={{ width: 24, height: 24, mr: 2 }}>
          {item.icon}
        </Box>}
        <Stack direction={"row"} justifyContent={"space-between"} width={"100%"}>
          <Box component="span">{item.title}</Box>
          {hasChildren && (open ? <ExpandLess /> : <ExpandMore />)}
        </Stack>
      </ListItemButton>
      {hasChildren && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Stack component="nav" spacing={0.5} sx={{ px: 2 }}>
            {item.children.map((child) => (
              <NavItem key={child.title} item={child} />
            ))}
          </Stack>
        </Collapse>
      )}
    </>
  );
}

NavItem.propTypes = {
  item: PropTypes.object,
};