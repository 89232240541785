import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import { server } from "src/constant/axios_config";
import updateObject from "src/utils/updateObject";

export const createCompany = createAsyncThunk("create/company", async (companyData, { rejectWithValue }) => {
  try {
    const response = await server.post("companies", companyData);
    console.log("this will be the post company data", response?.data)
    return response?.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const UpdateCompany = createAsyncThunk("update/company", async (companyData, { rejectWithValue }) => {
  try {
    const response = await server.patch(`companies/${companyData.id}`, companyData);
    return response?.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const fetchCompany = createAsyncThunk("ReadCompany/Data", async (payload, { rejectWithValue }) => {
  try {
    const { page = 0, pageSize = 5, sortedByKey = 'created_at', ascending = true, searchName = "", searchEmail, status = "" } = payload;
    let url = `companies?page=${page + 1 || ""}&page_size=${pageSize || ""}&name=${searchName || ""}&sort_by=${sortedByKey}&ascending=${ascending}`
    if (status !== "") url += `&status=${status || false}`
    const response = await server.get(url);
    return response?.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const updateCompanyStatus = createAsyncThunk("update/company/status", async (payload, { rejectWithValue }) => {
  try {
    const response = await server.put(`company/status/${payload?.id}?status=${payload?.data?.status}`);
    console.log("this will be the resposne status", response?.data)
    return response?.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const fetchCompanyById = createAsyncThunk("fetch/company/company_id", async (company_id, { rejectWithValue }) => {
  try {
    const response = await server.get(`companies/${company_id}`);
    return response?.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const companyLogoUpload = createAsyncThunk("upload/company/logo", async (companyData, { rejectWithValue }) => {
  try {
    const response = await server.post("upload-image?type=logo", companyData, { headers: { 'Content-Type': 'multipart/form-data' } });
    toast.success("Image uploaded successfully")
    return response?.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});




//slices creation for companies Data
export const companyDetail = createSlice({
  name: "companyDetail",
  initialState: {
    companies: [],
    singleCompany: null,
    selectedCompany: null,
    companyAdminID: sessionStorage?.getItem("companyId") || null,
    loading: false,
    error: null,
  },
  reducers: {
    setSingleCompany: (state, action) => {
      state.singleCompany = action.payload
    },
    setCompanyAdminID: (state, action) => {
      state.companyAdminID = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      // create company
      .addCase(createCompany.pending, (state) => {
        state.loading = true;
      })
      .addCase(createCompany.fulfilled, (state, action) => {
        state.loading = false;
        if(action.payload?.success == false){
          state?.companies?.list?.push(action?.payload?.data);
          toast.error('Company with same email already exist');
        }
        else if (action?.payload?.success == true) {
          state?.companies?.list?.push(action?.payload?.data);
          toast.success('Company Created Successfully');
        }
      })
      .addCase(createCompany.rejected, (state, action) => {
        state.loading = false;
      })
      // fetch all company
      .addCase(fetchCompany.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCompany.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.success) {
          state.companies = action.payload?.data;
        }
      })
      .addCase(fetchCompany.rejected, (state, action) => {
        state.loading = false;
        state.error = false;
      })
      // fetch company by email
      .addCase(fetchCompanyById.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCompanyById.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload?.success) {
          state.selectedCompany = action.payload?.data
        }
      })
      .addCase(fetchCompanyById.rejected, (state, action) => {
        state.loading = false;
      })

      // update company
      .addCase(UpdateCompany.pending, (state) => {
        state.loading = true;
      })
      .addCase(UpdateCompany.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.success) {
          toast.success('Company Updated Successfully');
        }
      })
      .addCase(UpdateCompany.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })

      // update company status
      .addCase(updateCompanyStatus.fulfilled, (state, action) => {
        if (action?.payload?.success) {
          toast.success("Company Status Has been updated");
          const updatedCompanies = state.companies.list.map((company) => {
            if (company.id === action.payload.data.id) {
              return updateObject(company, { status: action.payload.data.status });
            }
            return company;
          });
          state.companies.list = updatedCompanies;
        }
      })
  },
});

export default companyDetail.reducer;
export const companyAction = companyDetail?.actions;