import { createAsyncThunk } from '@reduxjs/toolkit';
import { server, defaultOptions } from 'src/constant/axios_config';


export const login = createAsyncThunk('auth/login', async (credentials, { rejectWithValue }) => {
  try {
    const response = await server.post("login", credentials, { ...defaultOptions })
    return response?.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const register = createAsyncThunk('auth/register', async (userData) => {
  try {
    const response = await server.post("register", userData, { ...defaultOptions })
    return response?.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const getMe = createAsyncThunk("fetch/user/me", async (email, { rejectWithValue }) => {
  try {
    const response = await server.get(`user/${email}`);
    return response?.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
})