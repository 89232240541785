import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import logo from "../../assets/logo/logo.png"
import { forgotPasswords } from 'src/redux/auth/forgetPasswordThunk';
import { useForm } from 'react-hook-form';
import { Box, Stack } from '@mui/material';
import { bgGradient } from 'src/theme/css';
import { alpha, useTheme } from '@mui/material/styles';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  form: {
    width: '100%',
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    gap: "20px"
  },
  button: {
    width: '100%',
    backgroundColor: "#000000",
    "&:hover": {
      backgroundColor: "#FF7725",
    },
  },
}));

function ForgotPassword() {
  const theme = useTheme();
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm();

  const dispatch = useDispatch();
  const forgotPasswordStatus = useSelector((state) => state.password.forgotPasswordStatus);


  const HandelSubmitDataForm = (data) => {
    dispatch(forgotPasswords(data?.email))
      .then((response) => {
        if (response.payload?.success) {
          toast.success("Mail Has Been Sent!");
          setEmail("")
        } else {
          toast.error("Failed to send email");
        }
      })
  };

  const renderForm = (<>
    <img
      src={logo}
      alt=""
      width={340}
      height={140}
      style={{ paddingRight: "10px" }}
    />
    <Typography variant="h5" className={classes.title}>
      Forgot Password
    </Typography>
    <Typography variant="h5" style={{ fontSize: "13px", textAlign: "center", marginBottom: "20px" }}>
      Type the email associated with your <span style={{ color: '#FF6609' }}>AfriTech </span> account and we will
      send the link on your email
    </Typography>
    <form noValidate onSubmit={handleSubmit(HandelSubmitDataForm)} className={classes.form}>
      <Box width={"100%"}>
        <TextField
          {...register("email", {
            required: { value: true, message: "Email is required" },
            pattern: {
              value: /^[^\s@]+(\+[^\s@]+)?@[^\s@]+\.[^\s@]+$/,
              message: "Invalid email address.",
            },
            onChange: (e) => setEmail(e?.target?.value)
          })}
          value={email}
          name="email"
          label="Email"
          type="email"
          fullWidth
          sx={{ mb: "10px" }}
        />
        {errors.email ? (<p className="text-danger"> {errors.email.message} </p>) : null}
      </Box>
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        disabled={forgotPasswordStatus === 'loading'}
        className={classes.button}
      >
        {(forgotPasswordStatus === 'loading' ? 'Sending...' : "Send Link")}
      </Button>
    </form>

    <Stack direction="row" alignItems="center" justifyContent="flex-end" sx={{ my: 3 }}>
      Back to&nbsp;
      <Link to="/" variant="subtitle2" underline="hover" style={{ color: "#FF6000" }}>
        Sign In?
      </Link>
    </Stack>
  </>);

  return (<Box
    sx={{
      ...bgGradient({
        color: alpha(theme.palette.background.default, 0.9),
        imgUrl: '/assets/background/overlay_4.jpg',
      }),
      height: 1,
    }}
  >
    <Stack alignItems="center" justifyContent="center" sx={{ height: 1 }}>
      {/* <Logo /> */}
      <Card
        sx={{
          p: 4,
          width: 1,
          maxWidth: 500,
        }}
        className={classes.card}
      >
        {renderForm}
      </Card>
    </Stack>
  </Box >);
}

export default ForgotPassword;