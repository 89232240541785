import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import LoadingScreen from 'src/components/loader/LoadingScreen';
// components
//

// ----------------------------------------------------------------------

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default function GuestGuard({ children }) {
  console.log('huet');
  const { isLoggedIn } = useSelector((state) => state.auth);
  console.log(isLoggedIn);

  if (isLoggedIn) {
    return <Navigate to="/" />;
  }

  if (isLoggedIn === null) {
    return <LoadingScreen />;
  }

  return <> {children} </>;
}
