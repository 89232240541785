import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import Popover from '@mui/material/Popover';
import { alpha } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { auth } from './../../../config/firebase';
import { account } from 'src/\_mock/account';
import { useRouter } from 'src/routes/hooks';
import { fetchUser } from 'src/redux/user/userSlice';
import { useSelector, useDispatch } from 'react-redux';
import logout from "../../../assets/icon/logout.png"
// Import the necessary components for the modal
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import SvgColor from 'src/components/svg-color';

const MENU_OPTIONS = [
  // { label: 'Home', icon: 'eva:home-fill' },
  { label: 'Profile', icon: 'eva:person-fill' },
  { label: 'Settings', icon: 'eva:settings-2-fill' },
];

const initialUser = { profile: '', name: '', email: '' };

export default function AccountPopover() {
  const [open, setOpen] = useState(null);
  const [openModal, setOpenModal] = useState(false); // State for the modal visibility
  const router = useRouter();
  const dispatch = useDispatch();
  // const user = useSelector((state) => state.user.users)
  const { currentUser } = useSelector((state) => state.auth);
  // useEffect(() => {
  //   dispatch(fetchUser());
  // }, [dispatch]);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogout = () => {
    setOpenModal(true); // Open the modal
  };

  const confirmLogout = () => {
    localStorage.clear();
    window.location.reload();
    router.push('/');
    auth.signOut();
    setOpenModal(false); // Close the modal after logging out
  };

  const renderUserAvatar = () => {


    return (
      <Avatar
        src={currentUser?.profile || ''}
        alt={currentUser?.profile || ''}
        sx={{
          width: 36,
          height: 36,
          border: (theme) => `solid 2px ${theme.palette.background.default}`,
        }}
      >
        {currentUser?.email ? currentUser?.email.charAt(0).toUpperCase() : ''}
      </Avatar>
    );
  };
  return (
    currentUser && <>
      <Box display={'flex'} alignItems={'center'} >
        <Box component="span" sx={{ width: 24, height: 24, mr: 2 }}>
          {currentUser.profile ? <Avatar src={currentUser.profile} alt="photoURL" /> :
            <SvgColor src={`/assets/icons/navbar/person.svg`} sx={{ width: 1, height: 1 }} />}
        </Box>
        <Box display={'flex'} flexDirection={'column'} >
          <Box component="span">{currentUser?.name || "-"}  </Box>
          <Box component="span" style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", width: 150 }}>{currentUser.email || "-"}  </Box>
        </Box>
      </Box>

      {/* Confirmation Modal */}
      <Dialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" >Confirm Logout</DialogTitle>
        <DialogContent>
          <img src={logout} alt="" height={160} width={290} />
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to logout?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenModal(false)} style={{ backgroundColor: "#FE6910", color: "white" }}>Cancel</Button>
          <Button onClick={confirmLogout} autoFocus style={{ backgroundColor: "#000000", color: "white" }}>
            Logout
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}