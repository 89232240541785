export const baseURL = 'https://stg-api.afritech-lossadjusters.com/'
export const imageUrl = 'https://stg-image.afritech-lossadjusters.com/'
export const documentURL = 'https://stg-document.afritech-lossadjusters.com/'

export const roles = {
    ROLE_SUPER_ADMIN: "Internal",
    ROLE_COMPANY_ADMIN: "External",
    ROLE_BROKER_ADMIN: "Broker",
    ROLE_LOSS_ASSESOR_ADMIN: "LossAssessor",
    ROLE_POLICY_OWNER_ADMIN: "PolicyOwner",
}

export const commonVarialbleValue = {
    details: 'details',
    add: 'create',
    update: 'update',
    internal: 'internal',
    external: 'external',
    broker: 'broker',
    lossAssessor: 'loss-assessor',
    policyOwner: 'policy-owner',
    user: 'user',
    role: 'role',
    company: 'company',
    insurancetype: 'insurancetype',
    insurances: 'insurance',
    document: 'document',
    companyAdmin: 'company-admin',
    brokerAdmin: 'broker-admin',
    lossAssessorAdmin: 'loss-assessor-admin',
    policyOwnerAdmin: 'policy-owner-admin',
    survey: 'survey',
    calendar: 'calendar',
    claim: 'claim',
    ila: 'ila',
    notifications: 'notifications'
}

export const routesPath = {
    superAdmin: {
        dashboard: '/',
        internalUser: `/${commonVarialbleValue?.user}/${commonVarialbleValue?.internal}`,
        externalUser: `/${commonVarialbleValue?.user}/${commonVarialbleValue?.external}`,
        brokerUser: `/${commonVarialbleValue?.user}/${commonVarialbleValue?.broker}`,
        lossAssesorUser: `/${commonVarialbleValue?.user}/${commonVarialbleValue?.lossAssessor}`,
        policyOwnerUser: `/${commonVarialbleValue?.user}/${commonVarialbleValue?.policyOwner}`,
        role: `/${commonVarialbleValue?.role}`,
        company: `/${commonVarialbleValue?.company}`,
        insuranceType: `/${commonVarialbleValue?.insurancetype}`,
        insurance: `/${commonVarialbleValue?.insurances}`,
        document: `/${commonVarialbleValue?.document}`,
        survey: `/${commonVarialbleValue.survey}`,
        claim: `/${commonVarialbleValue?.claim}`,
        notification: `/${commonVarialbleValue?.notifications}`,

    },
    companyAdmin: {
        dashboard: `/${commonVarialbleValue?.companyAdmin}`,
        broker: `/${commonVarialbleValue?.companyAdmin}/${commonVarialbleValue?.broker}`,
        user: `/${commonVarialbleValue?.companyAdmin}/${commonVarialbleValue?.user}`,
        insurance: `/${commonVarialbleValue?.companyAdmin}/${commonVarialbleValue?.insurances}`,
        lossAssesor: `/${commonVarialbleValue?.companyAdmin}/${commonVarialbleValue?.lossAssessor}`,
        policyOwner: `/${commonVarialbleValue?.companyAdmin}/${commonVarialbleValue?.policyOwner}`,
        notification: `/${commonVarialbleValue?.companyAdmin}/${commonVarialbleValue?.notifications}`,
    },
    broker: {
        dashboard: `/${commonVarialbleValue?.brokerAdmin}`,
        notification: `/${commonVarialbleValue?.notifications}`,
    },
    lossAssesor: {
        dashboard: `/${commonVarialbleValue?.lossAssessorAdmin}`,
        survey: `/${commonVarialbleValue?.lossAssessorAdmin}/${commonVarialbleValue.survey}`,
        calendar: `/${commonVarialbleValue?.lossAssessorAdmin}/${commonVarialbleValue?.calendar}`,
        claim: `/${commonVarialbleValue?.lossAssessorAdmin}/${commonVarialbleValue?.claim}`,
        notification: `/${commonVarialbleValue?.lossAssessorAdmin}/${commonVarialbleValue?.notifications}`,
    },
    policyOwner: {
        dashboard: `/${commonVarialbleValue?.policyOwnerAdmin}`,
        notification: `/${commonVarialbleValue?.notifications}`,
    },
    add: `/${commonVarialbleValue?.add}`,
    update: `/${commonVarialbleValue?.update}`,
    details: `/${commonVarialbleValue?.details}`
}

export const countries = [
    'Algeria',
    'Angola',
    'Benin',
    'Botswana',
    'Burkina Faso',
    'Burundi',
    'Cabo Verde',
    'Cameroon',
    'Central African Republic',
    'Chad',
    'Comoros',
    'Congo (Congo-Brazzaville)',
    'Democratic Republic of the Congo',
    'Djibouti',
    'Egypt',
    'Equatorial Guinea',
    'Eritrea',
    'Eswatini (fmr. "Swaziland")',
    'Ethiopia',
    'Gabon',
    'Gambia',
    'Ghana',
    'Guinea',
    'Guinea-Bissau',
    'Ivory Coast',
    'Kenya',
    'Lesotho',
    'Liberia',
    'Libya',
    'Madagascar',
    'Malawi',
    'Mali',
    'Mauritania',
    'Mauritius',
    'Morocco',
    'Mozambique',
    'Namibia',
    'Niger',
    'Nigeria',
    'Rwanda',
    'Sao Tome and Principe',
    'Senegal',
    'Seychelles',
    'Sierra Leone',
    'Somalia',
    'South Africa',
    'South Sudan',
    'Sudan',
    'Tanzania',
    'Togo',
    'Tunisia',
    'Uganda',
    'Zambia',
    'Zimbabw'
]