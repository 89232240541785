import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import { server } from "src/constant/axios_config";
import updateObject from "src/utils/updateObject";

export const createInsuranceType = createAsyncThunk("create/insurance-type", async (insuranceData, { rejectWithValue }) => {
  try {
    const response = await server.post("insurance-types", insuranceData);
    return response?.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const updateInsuranceType = createAsyncThunk("update/insurace-type", async (insuranceData, { rejectWithValue }) => {
  try {
    const response = await server.patch(`insurance-types/${insuranceData.id}`, insuranceData);
    return response?.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const fetchInsuranceType = createAsyncThunk("insuraceInsuranceType/Data", async (payload, { rejectWithValue }) => {
  try {
    const { page = 0, pageSize = 5, sortedByKey = 'created_at', ascending = true, searchName = "", searchEmail, status = "" } = payload;
    let url = `insurance-types?page=${page + 1 || ""}&page_size=${pageSize || ""}&name=${searchName || ""}&sort_by=${sortedByKey}&ascending=${ascending}`
    if (status !== "") url += `&status=${status || false}`
    const response = await server.get(url);
    return response?.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const updateInsuranceTypeStatus = createAsyncThunk("update/insurancetype/status", async (payload, { rejectWithValue }) => {
  try {
    const response = await server.put(`insurance-types/status/${payload?.id}?status=${payload?.data?.status}`);
    return response?.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const fetchInsuranceTypeById = createAsyncThunk("fetch/insurance/insurancetype_id", async (insurancetype_id, { rejectWithValue }) => {
  try {
    const response = await server.get(`insurance-types/${insurancetype_id}`);
    return response?.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});



//slices creation for companies Data
export const insurancetypeDetail = createSlice({
  name: "insurancetypeDetail",
  initialState: {
    insurancetype: [],
    singleInsuranceType: null,
    selectedInsuranceType: null,
    insurancetypeAdminID: sessionStorage?.getItem("insurancetypeId") || null,
    loading: false,
    error: null,
    searchData: [],
  },
  reducers: {
    setSingelInsuranceType: (state, action) => {
      state.singleInsuranceType = action.payload
    },
    setinsurancetypeAdminID: (state, action) => {
      state.insurancetypeAdminID = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      // create InsuranceType
      .addCase(createInsuranceType.pending, (state) => {
        state.loading = true;
      })
      .addCase(createInsuranceType.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.success) {
          state?.insurancetype?.list?.push(action?.payload?.data);
          toast.success('InsuranceType Created Successfully');
        }
      })
      .addCase(createInsuranceType.rejected, (state, action) => {
        state.loading = false;
      })
      // fetch all InsuranceType
      .addCase(fetchInsuranceType.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchInsuranceType.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.success) {
          state.insurancetype = action.payload?.data;
        }
      })
      .addCase(fetchInsuranceType.rejected, (state, action) => {
        state.loading = false;
        state.error = false;
      })
      // fetch InsuranceType by email
      .addCase(fetchInsuranceTypeById.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchInsuranceTypeById.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload?.success) {
          state.selectedInsuranceType = action.payload?.data
        }
      })
      .addCase(fetchInsuranceTypeById.rejected, (state, action) => {
        state.loading = false;
      })

      // update InsuranceType
      .addCase(updateInsuranceType.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateInsuranceType.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.success) {
          toast.success('InsuraceType Updated Successfully');
        }
      })
      .addCase(updateInsuranceType.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })

      // update InsuranceType status
      .addCase(updateInsuranceTypeStatus.fulfilled, (state, action) => {
        if (action?.payload?.success) {
          toast.success("InsuranceType Status Has been updated");
          const updatedInsureType = state.insurancetype.list.map((InsuranceType) => {
            if (InsuranceType.id === action.payload.data.id) {
              return updateObject(InsuranceType, { status: action.payload.data.status });
            }
            return InsuranceType;
          });
          state.insurancetype.list = updatedInsureType;
        }
      })
  },
});

export default insurancetypeDetail.reducer;
export const InsuranceTypeAction = insurancetypeDetail?.actions;