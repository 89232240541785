import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Autocomplete, Box, Checkbox, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, Stack, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useForm, Controller } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { ReadPermission } from 'src/redux/roles&permission/permissionSlice';
import { CreateRoles, UpdateRoles, fetchRoleById } from 'src/redux/roles&permission/roleSlice';
import Container from '@mui/material/Container';
import { useNavigate, useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import Iconify from 'src/components/iconify/iconify.jsx';
import { fetchCompany } from 'src/redux/company/companySlice.js';
import PageTitle from 'src/common/component/PageTitle';
import LoadingScreen from 'src/components/loader/LoadingScreen';


const FormContainer = styled("form")(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

export default function AddUpdateRoleView() {

  const navigate = useNavigate()
  const { permission, tokenPermissionsData, permissionsData } = useSelector((state) => state.permission);
  const { loading } = useSelector((state) => state.role);
  const isDetailsPage = location.hash.split("/").includes("details");
  const [Permission, setPermission] = useState([]);
  const [selected, setSelected] = useState(null);
  const [titles, setTitles] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [companyList, setCompanyList] = useState([])
  const [roleType, setRoleType] = useState('');
  const dispatch = useDispatch()
  const params = useParams()

  const { register, setValue, handleSubmit, watch, getValues, reset, formState: { errors }, control } = useForm({ defaultValues: { role: '', description: '', roleType: '', company_id: '' } });


  useEffect(() => {
    dispatch(fetchCompany({ pageSize: 5000, status: true })).unwrap().then((res) => {
      if (res?.success) setCompanyList(res?.data?.list)
    })
  }, [])

  useEffect(() => {
    if (permissionsData?.length > 0) {
      const title = new Set(permissionsData?.map(item => item?.title));
      setTitles([...title])
      var newpermissions = []
      if (roleType === 'external') {
        newpermissions = permissionsData?.filter(obj => !obj?.is_internal)
      } else {
        newpermissions = permissionsData?.filter(obj => obj?.is_internal)
      }
      newpermissions = newpermissions?.map(obj => {
        return { ...obj, status: obj?.permission?.includes("dashboard") ? true : false };
      })
      if (selected?.permissions?.length) newpermissions = updateManyItems(newpermissions, selected?.permissions)
      setPermission([...newpermissions])
    }
  }, [permissionsData, selected?.permissions?.length, roleType])

  var roleTypeW = watch('roleType')

  useEffect(() => {
    setRoleType(roleTypeW)
  }, [roleTypeW])

  function updateManyItems(existingItems, updatedItems) {
    // console.log("existingItemsupdatedItems", existingItems, updatedItems)
    return existingItems.map(existingItem => {
      var updatedItem = updatedItems?.find(item => item === existingItem?.permission);
      // console.log("updatedItemsfind==========>", updatedItem)
      var newupdatedItem = { value: updatedItem, label: updatedItem, status: true }
      // console.log("newupdatedItem==========>", newupdatedItem)
      return updatedItem ? { ...existingItem, ...newupdatedItem } : existingItem;
    });
  }

  useEffect(() => {
    if (selected?.description) setValue("description", selected?.description)
    if (selected?.role) setValue("role", selected?.role)
    if (selected?.company_id) setValue("company_id", selected?.company_id)
    setValue("roleType", selected?.is_internal ? "internal" : "external")
  }, [selected?.id])

  useEffect(() => {
    if (companyList?.length && selected) {
      setSelectedCompany(companyList?.find((el) => el?.id === selected?.company_id))
    }
  }, [companyList, selected])

  useEffect(() => {
    if (params?.id) {
      dispatch(fetchRoleById(params?.id)).then((res) => {
        setSelected(res?.payload?.data)
      })
    }
  }, [params?.email])

  useEffect(() => {
    dispatch(ReadPermission());
  }, [])

  const onSubmit = async (data) => {
    data.permissions = Permission.filter(val => val?.status);
    // console.log("data.permissions", data.permissions)
    data.permissions = data.permissions?.map(val => val?.permission);
    // console.log("data.permissions", data.permissions)
    data.is_internal = roleType === 'external' ? false : true;
    if (roleType !== 'external') {
      delete data.company_id;
    }
    try {
      if (selected?.id) {
        data.id = selected?.id;
        let res = await dispatch(UpdateRoles(data));
        if (res?.payload?.success) {
          navigate(-1);
        } else {
          toast.error(res?.message);
        }
      } else {
        if (data?.permissions?.length) {
          data.company_id = "";
          let res = await dispatch(CreateRoles(data));
          if (res?.payload?.success) {
            navigate(-1);
          } else {
            toast.error(res?.payload?.message);
          }
        } else {
          toast.error("Please select at least one permission.");
        }
      }
    } catch (e) { console.error(e); toast.error(e?.response?.data?.message); }
  };
  // console.log("Permission", Permission)
  return <Container maxWidth="xl">
    {loading && <LoadingScreen />}
    <Box mb={5}>
      <PageTitle title={isDetailsPage ? "Role Details" : selected?.id ? "Edit Role" : "Add New Role"} />
    </Box>
    <Card>
      <FormContainer noValidate onSubmit={handleSubmit(onSubmit)} >
        <DialogContent  >
          <Stack spacing={3} >
            <TextField  {...register('role', { required: true, validate: value => value.trim() !== '' })}
              InputLabelProps={{ shrink: selected?.role }}
              variant="outlined" size='small'
              label="Enter role"
              placeholder="Role"
              disabled={isDetailsPage}
            />
            {errors.role && <p className="text-danger " style={{ fontSize: 12 }}>Role is required.</p>}

            <TextField multiline
              rows={3}
              maxRows={4} disabled={isDetailsPage} size='small' InputLabelProps={{ shrink: selected?.description }} fullWidth name="description" label="Enter description" placeholder='Description'   {...register('description', { required: true, validate: value => value.trim() !== '' })} />
            {errors.description && <p className="text-danger " style={{ fontSize: 12 }}>Description is required.</p>}

            <div style={{ display: 'flex', alignItems: 'center' }}>
              <FormControl component="fieldset">
                <FormLabel component="legend" id="demo-row-radio-buttons-group-label">Role Type</FormLabel>
                <Controller
                  name="roleType"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      {...field}
                    >
                      <FormControlLabel disabled={isDetailsPage} value="internal" control={<Radio />} label="Internal" />
                      <FormControlLabel disabled={isDetailsPage} value="external" control={<Radio />} label="External" />
                    </RadioGroup>
                  )}
                />
              </FormControl>
            </div>

            {(roleType === 'external') ? (
              <>
                <Controller
                  render={({ onChange, ...props }) => {
                    return (
                      <Autocomplete
                        {...register("company_id", { required: true, validate: value => value?.trim() !== '' })}
                        options={companyList}
                        getOptionLabel={option => option.name}
                        renderInput={params => (
                          <TextField
                            {...params}
                            label="Company Name"
                            variant="outlined"
                          />
                        )}
                        disabled={isDetailsPage}
                        value={selectedCompany}
                        onChange={(event, newValue) => {
                          setSelectedCompany(newValue);
                          setValue("company_id", newValue?.id)
                        }}
                        {...props}
                      />
                    )
                  }}
                  name="company_id"
                  control={control}

                />
                {errors.company_id ? (<p className="text-danger">Company is required </p>) : null}
              </>) : <></>
            }


            <Box display={'flex'} flexWrap={'wrap'} alignItems={'center'}>
              {isDetailsPage ?
                titles?.map(title => {
                  return <Box key={title} width={"100%"}>
                    {Permission?.find(p => p?.title === title && p.status)?.status && <Typography noWrap style={{ fontWeight: 900 }} display={'block'} color={'primary'} textTransFormContainer={'uppercase'}>  <Iconify icon="eva:arrow-ios-forward-outline" sx={{ width: 12, verticalAlign: "sub" }} /> {title?.toUpperCase()}</Typography>}
                    <Grid container spacing={2} my={1}>
                      {Permission?.length && Permission?.map((i, ind) => {
                        return (i?.title === title && i?.status) && <Grid xs={12} sm={6} md={6} lg={6} xl={6} >
                          <Box ml={4} >
                            <Checkbox disabled={isDetailsPage} checked={i?.status} />
                            <Typography variant='span'>{i?.permission}</Typography>
                          </Box>
                        </Grid>
                      })}
                    </Grid>
                  </Box>
                })
                : titles?.map(title => (
                  <Box key={title} width={"100%"}>
                    {Permission?.find(p => p?.title === title) ? <>
                      <Typography my={1} noWrap style={{ fontWeight: 600 }} fontSize={16} display={'block'} color={'primary'} textTransFormContainer={'uppercase'}> <Iconify icon="eva:arrow-ios-forward-outline" sx={{ width: 12, verticalAlign: "sub" }} /> {title?.toUpperCase()}</Typography>
                      <Grid container spacing={2} my={1}>
                        {Permission?.length && Permission?.map((i, ind) => {
                          return (i?.title === title ? <Grid xs={12} sm={6} md={6} lg={6} xl={6} >
                            <Box ml={4} >
                              <Checkbox checked={i?.status} sx={{ p: 0.7 }} onChange={(e) => {
                                Permission[ind].status = e.target.checked;
                                setPermission([...Permission])
                              }} />
                              <Typography variant='span' fontSize={14}>{i?.permission}</Typography>
                            </Box>
                          </Grid > : <></>)
                        })}
                      </Grid>
                    </> : <></>}
                  </Box>
                ))}
            </Box>

          </Stack>
        </DialogContent>
        {!isDetailsPage && <DialogActions>
          <LoadingButton typeof='submit'
            size="medium"
            type="submit"
            variant="contained"
            style={{ backgroundColor: "#000000" }}
            backgroundColor="#000000"
          >
            {selected?.id ? "Update" : "Add"}
          </LoadingButton>
        </DialogActions>}
      </FormContainer >
    </Card>
  </Container>
}