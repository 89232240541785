import SvgColor from 'src/components/svg-color';
import { Typography } from '@mui/material';
import { routesPath } from 'src/constant/contant';
// // ----------------------------------------------------------------------
const icon = (name = "", color = 'inherit') => (
  name ? <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{
      width: 1,
      height: 1,
    }}
  /> : ""
);

export const navConfig = [
  {
    title: 'dashboard',
    path: routesPath?.superAdmin?.dashboard,
    icon: icon('ic_analytics', "#FF6000"),
  },
  {
    title: 'user',
    path: '#',
    icon: icon('users', '#FF6000'),
    children: [
      {
        title: 'Internal',
        path: routesPath?.superAdmin?.internalUser,
        icon:  icon('dot', "#FF6000"),
      },
      {
        title: 'External',
        path: routesPath?.superAdmin?.externalUser,
        icon:  icon('dot', "#FF6000"),
      },
      {
        title: 'Broker',
        path: routesPath?.superAdmin?.brokerUser,
        icon:  icon('dot', "#FF6000"),
      },
      {
        title: 'Loss Assessor',
        path: routesPath?.superAdmin?.lossAssesorUser,
        icon:  icon('dot', "#FF6000"),
      },
      {
        title: 'Policy Owner',
        path: routesPath?.superAdmin?.policyOwnerUser,
        icon:  icon('dot', "#FF6000"),
      }
    ]
  },
  {
    title: 'Insurance Company',
    path: routesPath?.superAdmin?.company,
    icon: icon('company', '#FF6000'),
  },
  {
    title: 'Insurance',
    path: routesPath?.superAdmin?.insurance,
    icon: icon('insurance', '#FF6000')
  },
  {
    title: 'Claim',
    path: routesPath?.superAdmin?.claim,
    icon: icon('calendar', '#FF6000'),
  },
  {
    title: 'Survey',
    path: routesPath?.superAdmin?.survey,
    icon: icon('survey', '#FF6000'),
  },
  {
    title: 'Master',
    path: '#',
    icon: icon('users', '#FF6000'),
    children: [
      {
        title: 'Role',
        path: routesPath?.superAdmin?.role,
        icon:  icon('dot', "#FF6000"),
      },
      {
        title: 'Insurance Type',
        path: routesPath?.superAdmin?.insuranceType,
        icon:  icon('dot', "#FF6000"),
      },
      {
        title: 'Document',
        path: routesPath?.superAdmin?.document,
        icon:  icon('dot', "#FF6000"),
      },
    ]
  },
  {
    title: 'Notification',
    path: routesPath?.superAdmin?.notification,
    icon: icon('bell', '#FF6000')
  },
];

export const navConfigCompany = [
  {
    title: 'dashboard',
    path: routesPath?.companyAdmin?.dashboard,
    icon: icon('ic_analytics', "#FF6000"),
  },
  {
    title: 'Users',
    path: routesPath?.companyAdmin?.user,
    icon: icon('users', '#FF6000'),
  },
  {
    title: 'Broker',
    path: routesPath?.companyAdmin?.broker,
    icon: icon('brokerage', '#FF6000'),
  },
  // {
  //   title: 'Loss Assessor',
  //   path: routesPath?.companyAdmin?.lossAssesor,
  //   icon: icon('insurance', '#FF6000'),
  // },
  {
    title: 'Policy Owner',
    path: routesPath?.companyAdmin?.policyOwner,
    icon: icon('insurance', '#FF6000'),
  },
  {
    title: 'Insurance',
    path: routesPath?.companyAdmin?.insurance,
    icon: icon('insurance', '#FF6000'),
  },
  {
    title: 'Claim',
    path: routesPath?.superAdmin?.claim,
    icon: icon('calendar', '#FF6000'),
  },
  {
    title: 'Survey',
    path: routesPath?.superAdmin?.survey,
    icon: icon('survey', '#FF6000'),
  },
  {
    title: 'Notification',
    path: routesPath?.superAdmin?.notification,
    icon: icon('notification', '#FF6000')
  },

];

export const navConfigBroker = [
  {
    title: 'dashboard',
    path: routesPath?.broker?.dashboard,
    icon: icon('ic_analytics', "#FF6000"),
  },
  {
    title: 'Broker Test 1',
    path: '#',
    icon: icon('insurance', '#FF6000'),
  }, {
    title: 'Broker Test 2',
    path: '#',
    icon: icon('insurance', '#FF6000'),
  }, {
    title: 'Broker Test 3',
    path: '#',
    icon: icon('insurance', '#FF6000'),
  },
  {
    title: 'Notification',
    path: routesPath?.superAdmin?.notification,
    icon: icon('notification', '#FF6000')
  },
];

export const navConfigLossAssesor = [
  {
    title: 'dashboard',
    path: routesPath?.lossAssesor?.dashboard,
    icon: icon('ic_analytics', "#FF6000"),
  },
  {
    title: 'Claim',
    path: routesPath?.lossAssesor?.claim,
    icon: icon('calendar', '#FF6000'),
  },
  {
    title: 'Survey',
    path: routesPath?.lossAssesor?.survey,
    icon: icon('survey', '#FF6000'),
  },
  {
    title: 'Notification',
    path: routesPath?.superAdmin?.notification,
    icon: icon('notification', '#FF6000')
  },
  // {
  //   title: 'Calendar',
  //   path: routesPath?.lossAssesor?.calendar,
  //   icon: icon('calendar', '#FF6000'),
  // },
  // {
  //   title: 'Asseson Test 1',
  //   path: '#',
  //   icon: icon('insurance', '#FF6000'),
  // },
  // {
  //   title: 'Asseson Test 2',
  //   path: '#',
  //   icon: icon('insurance', '#FF6000'),
  // }, {
  //   title: 'Asseson Test 3',
  //   path: '#',
  //   icon: icon('insurance', '#FF6000'),
  // },
];

export const navConfigPolicyOwner = [
  {
    title: 'dashboard',
    path: routesPath?.policyOwner?.dashboard,
    icon: icon('ic_analytics', "#FF6000"),
  },
  {
    title: 'Policy Test 1',
    path: '#',
    icon: icon('insurance', '#FF6000'),
  }, {
    title: 'Policy Test 2',
    path: '#',
    icon: icon('insurance', '#FF6000'),
  }, {
    title: 'Policy Test 3',
    path: '#',
    icon: icon('insurance', '#FF6000'),
  },
  {
    title: 'Notification',
    path: routesPath?.superAdmin?.notification,
    icon: icon('notification', '#FF6000')
  },

];

// export default navConfig;
