import { createSlice } from '@reduxjs/toolkit';
import { forgotPasswords, resetPasswords } from './forgetPasswordThunk';

const initialState = {
  forgotPasswordStatus: 'idle',
  forgotPasswordError: null,
  resetPasswordStatus: 'idle',
  resetPasswordError: null,
};

const forgetPasswordSlice = createSlice({
  name: 'password',
  initialState,
  reducers: {
    
  },
  extraReducers: (builder) => {
    builder
      .addCase(forgotPasswords.pending, (state) => {
        state.forgotPasswordStatus = 'loading';
        state.forgotPasswordError = null;
      })
      .addCase(forgotPasswords.fulfilled, (state, action) => {
        state.forgotPasswordStatus = 'succeeded';
        // handle successful response
      })
      .addCase(forgotPasswords.rejected, (state, action) => {
        state.forgotPasswordStatus = 'failed';
        state.forgotPasswordError = action.payload;
      })
      .addCase(resetPasswords.pending, (state) => {
        state.resetPasswordStatus = 'loading';
        state.resetPasswordError = null;
      })
      .addCase(resetPasswords.fulfilled, (state, action) => {
        state.resetPasswordStatus = 'succeeded';
        // handle successful response
      })
      .addCase(resetPasswords.rejected, (state, action) => {
        state.resetPasswordStatus = 'failed';
        state.resetPasswordError = action.payload;
      });
  },
});

export default forgetPasswordSlice.reducer;