import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { error } from "src/theme/palette";
import { server } from "src/constant/axios_config";
import { toast } from "react-toastify";
import updateObject from "src/utils/updateObject";

//Create notification by createAsyncThunk
export const createNotification = createAsyncThunk("create/notification", async (data, { rejectWithValue }) => {
  try {
    const response = await server.post("notification", data);
    return response?.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

//fetch notification by id
export const fetchNotificationById = createAsyncThunk("fetch/notification/id", async (id, { rejectWithValue }) => {
  try {
    const response = await server.get(`notification/${id}`);
    return response?.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

//Read notification by createAsyncThunk
export const fetchNotification = createAsyncThunk("insuraceInsurance/Data", async (payload, { rejectWithValue }) => {
  try {
    const { page = 0, pageSize = 5, sortedByKey = 'created_at', ascending = false, searchName = "", user_id = "" } = payload;
    const response = await server.get(`notification?page=${page + 1 || ""}&page_size=${pageSize || ""}&name=${searchName || ""}&sort_by=${sortedByKey}&ascending=${ascending}&user_id=${user_id}`);
    return response?.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

//Update notification by createAsyncThunk
export const updateNotification = createAsyncThunk("updates/notification", async (data, { rejectWithValue }) => {
  try {
    const response = await server.patch(`notification/${data.id}`, data);
    return response?.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

// Update status NotificationSlice.js
export const updateNotificationStatus = createAsyncThunk("update/notification/status", async (payload, { rejectWithValue }) => {
  try {
    const response = await server.put(`notification/status/${payload?.id}?status=${payload?.data?.status}`);
    return response?.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

//delete notification with createAsyncThunk
export const deleteNotification = createAsyncThunk("delete/notification", async (id, { rejectWithValue }) => {
  try {
    const response = await server.delete(`notification/${id}`);
    return response?.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

//slices creation for notification Data
export const notificationDetails = createSlice({
  name: "notification",
  initialState: {
    notification: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // update notification status

      .addCase(updateNotificationStatus.pending, (state, action) => {
        // Handle the error if needed
        state.loading = true
      })
      .addCase(updateNotificationStatus.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.success) {
          toast.success("Insurance Status Has been updated");
          const updatedNotification = state.notification.list.map((notification) => {
            if (notification.id === action.payload.data.id) {
              return updateObject(notification, { status: action.payload.data.status });
            }
            return notification;
          });
          state.notification.list = updatedNotification;
        }
      })
      .addCase(updateNotificationStatus.rejected, (state, action) => {
        // Handle the error if needed
        state.loading = false;
      })

      // create notification
      .addCase(createNotification.pending, (state) => {
        state.loading = true;
      })
      .addCase(createNotification.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.success) {
          state.notification?.list?.push(action.payload?.data);
          toast.success("Notification Created Succesfully")
        }
      })
      .addCase(createNotification.rejected, (state, action) => {
        state.loading = false;
      })

      // fetch notifications
      .addCase(fetchNotification.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchNotification.fulfilled, (state, action) => {
        state.loading = false;
        state.notification = action.payload?.data;
      })
      .addCase(fetchNotification.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // fetch Insurance by id
      .addCase(fetchNotificationById.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchNotificationById.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload?.success) {
        }
      })
      .addCase(fetchNotificationById.rejected, (state, action) => {
        state.loading = false;
      })


      // delete notifications
      .addCase(deleteNotification.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteNotification.fulfilled, (state, action) => {
        state.loading = false;
        const { id } = action.payload;
        if (id) {
          state.notification = state.notification.filter((ele) => ele.id !== id);
        }
      })
      .addCase(deleteNotification.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // update notification
      .addCase(updateNotification.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateNotification.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.success) {
          toast.success('Insuraces Updated Successfully');
        }
      })
      .addCase(updateNotification.rejected, (state, action) => {
        state.error = action.payload.message;
        state.loading = false;
      });


  },
});

export default notificationDetails.reducer;